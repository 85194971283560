/* eslint-disable no-unused-expressions */
import {
  MutableRefObject, useCallback, useEffect, useState,
} from 'react';
import {
  ISpinnerDataScheme,
  ISpinnerDataPossibilityInnerData, IWindRose, ISpinnerFitType,
} from 'src/typings/spinner';
import { useGetPlacementDataById } from 'src/hooks/filters';
// @ts-ignore
import { fsUtils } from 'viewer';
import { IRotateEvent } from 'src/components/ViewSpinner/types';
import { useSelector } from 'react-redux';
import { getCurrentSpinner } from 'src/store/spinner/selectors';
import { useFloorSpinnerInteraction } from 'src/components/ViewSpinner/components/RenderSvg/hooks';
import {
  useGetSelectedApartment,
} from 'src/components/FilterProperty/components/FilterDesktop/components/FloorsHouseSpinner/useGetSelectedApartment';
import { useGetFilteredIds } from 'src/components/ApartmentPageV2/ViewSpinner/components/RenderSvg/hooks';
import { resizeEmulate } from 'src/helpers';
import { ISpinnerElements, ISpinnerState } from '../types';

export const useInitSpinner = (
  spinnerData: ISpinnerDataPossibilityInnerData,
  randomSpinnerId: string,
  hideWindRose: boolean,
  // hideFloor: boolean,
  type: string | undefined,
  initWindRoseData: Function | null,
  objectFit: ISpinnerFitType,
  spinnerRef: MutableRefObject<HTMLDivElement | undefined>,
  appendChildHandler: ({ child }: { target: any, child: any; }) => void,
) => {
  const { getIdsByType } = useGetFilteredIds();
  const { placementData } = useGetPlacementDataById(getIdsByType() || ['0']);
  // const {
  //   handleActionForFloor,
  // } = useFloorSpinnerInteraction();
  // const { selectedApartmentId } = useGetSelectedApartment();
  // handleActionForFloor(selectedApartmentId);
  const { devicePixelRatio } = window;
  const [listOfStops, setListOfStops] = useState<any[]>([]);
  const [spinner, setSpinner] = useState<any>();
  const [spinnerState, setSpinnerState] = useState<ISpinnerState>({
    stopFrame: '0',
    dw: 0,
    dh: 0,
    dx: 0,
    dy: 0,
  });
  const currentSpinner = useSelector(getCurrentSpinner);

  const [svgSize, setSvgSize] = useState<{w: number, h: number}>({ w: 0, h: 0 });

  const [spinnerElements, setSpinnerElements] = useState<ISpinnerElements>({
    spinnerCanvas: null,
    spinnerPlace: null,
    spinnerControl: null,
    progressBar: null,
    turnLeft: null,
    turnRight: null,
    spinnerZoom: null,
    spinnerUnZoom: null,
    windRose: null,
  });

  const handleSetStopFrame = useCallback((isHide?: boolean) => {
    if (isHide) {
      setSpinnerState({
        ...spinnerState,
        stopFrame: '-1',
      });
    }
  }, [spinnerState, setSpinnerState]);

  const handleResizeForSvg = useCallback(() => {
    if (
      spinnerState.dh !== spinner?.viewBox?.dh
      || spinnerState.dw !== spinner?.viewBox?.dw
      || spinnerState.dx !== spinner?.viewBox?.dx
      || spinnerState.dy !== spinner?.viewBox?.dy
      || spinnerState.stopFrame !== String(spinner.controls.frame.x)
    ) {
      setSpinnerState({
        stopFrame: spinner?.controls.frame.x || '0',
        dw: spinner?.viewBox?.dw || 0,
        dh: spinner?.viewBox?.dh || 0,
        dx: spinner?.viewBox?.dx || 0,
        dy: spinner?.viewBox?.dy || 0,
      });
    }
  }, [spinner, spinnerState]);

  const detectBestStopFrame = useCallback(() => {
    const stops: any = {};
    const spinnerAdditionalId = spinnerData.additional_data.id;
    placementData?.forEach((item) => {
      const placementBestSpinnerStops = item?.['placement.best_spinner_stops']
        ?.filter((el) => el['entity-view-name-id'] === spinnerAdditionalId);
      if (Object.keys(stops).includes(placementBestSpinnerStops?.[0]?.stopNumber)) {
        stops[placementBestSpinnerStops?.[0]?.stopNumber] += 1;
      } else {
        stops[placementBestSpinnerStops?.[0]?.stopNumber] = 1;
      }
    });
    const res = Object.keys(stops).reduce((acc, item) => {
      if (stops[item] > stops[acc] || !acc) {
        return item;
      }
      return acc;
    }, '');
    return res;
  }, [placementData, spinnerData]);

  const handleOnProgressFSSpinner = (e: any) => {
    if (spinnerElements.progressBar) {
      // @ts-ignore
      spinnerElements.progressBar.style.width = `${window.Spinner.map(e.loaded, 0, e.total)}%`;
    }
  };

  const handleOnLoadEndFSSpinner = () => {
    if (spinnerElements.progressBar) {
      spinnerElements.progressBar.style.width = '0';
    }
  };

  function control(this: any, e: any) {
    if (this.classList.contains('disabled')) return;

    if (this === spinnerElements.turnLeft) spinner.controls.turnLeft();
    else if (this === spinnerElements.turnRight) spinner.controls.turnRight();
    else if (spinnerElements.spinnerCanvas) {
      if (this === spinnerElements.spinnerZoom) spinner.zoomFrame(0.1);
      else spinner.zoomFrame(-0.1);

      handleResizeForSvg();
    }
  }

  const windRoseRotate = useCallback((event: IRotateEvent) => {
    const windRoseImg = spinnerElements.windRose?.querySelector('div');

    if (initWindRoseData) {
      const windRoseData = initWindRoseData();
      if (windRoseImg && windRoseData && event?.progress) {
        // @ts-ignore
        windRoseImg.style.transform = `rotateZ(${windRoseData.degree + (360 * event.progress.x)}deg)`;
      }
    }
  }, [spinner, initWindRoseData]);

  const canvasResize = () => {
    if (spinnerElements.spinnerCanvas && spinnerElements.spinnerPlace) {
      spinnerElements.spinnerCanvas.style.width = `${spinnerElements.spinnerPlace.clientWidth}px`;
      spinnerElements.spinnerCanvas.style.height = `${spinnerElements.spinnerPlace.clientHeight}px`;

      spinner.resize(spinnerElements.spinnerCanvas.style.width, spinnerElements.spinnerCanvas.style.height);
    }
  };

  const handleWindowResize = () => {
    canvasResize();
    handleResizeForSvg();
  };

  useEffect(() => {
    if (spinnerElements.spinnerCanvas === null) {
      const elements: ISpinnerElements = {
        spinnerCanvas: document.querySelector<HTMLCanvasElement>(`#spinner_app_canvas${randomSpinnerId}`),
        spinnerPlace: document.querySelector<HTMLElement>(`#spinner_place-v2${randomSpinnerId}`),
        spinnerControl: document.querySelector<HTMLElement>(`#spinner_control-v2${randomSpinnerId}`),
        progressBar: document.querySelector<HTMLElement>(`#porgress_bar${randomSpinnerId}`),
        turnLeft: document.querySelector<HTMLElement>(`#turn_left${randomSpinnerId}`),
        turnRight: document.querySelector<HTMLElement>(`#turn_right${randomSpinnerId}`),
        spinnerZoom: document.querySelector<HTMLElement>(`#spinner_zoom${randomSpinnerId}`),
        spinnerUnZoom: document.querySelector<HTMLElement>(`#spinner_unzoom${randomSpinnerId}`),
      };
      // if (!hideFloor) {
      //   elements.spinnerCanvas = document.querySelector<HTMLCanvasElement>(`#spinner_app_canvas${randomSpinnerId}`);
      // }
      if (!hideWindRose) {
        elements.windRose = document.querySelector<HTMLElement>(`#wind_rose${randomSpinnerId}`);
      }
      setSpinnerElements(elements);
    }
  }, [spinnerElements, randomSpinnerId, hideWindRose]);

  useEffect(() => {
    if (spinnerData && !spinner && spinnerElements.spinnerCanvas && currentSpinner && fsUtils) {
      const spinnerConf = {
        stops: spinnerData.stops,
        maxZoom: 5,
        turnSpeed: 30,
        ...spinnerData,
        count: spinnerData.count,
        objectFit,
      };
      const tempSpinner = fsUtils.createSpinner(appendChildHandler);
      fsUtils.mergeConfig(
        spinnerConf, type || currentSpinner.name, spinnerData.additional_data.id,
      ).then((tempConfig: any) => {
        fsUtils.loadSpinnerConfig(tempSpinner, tempConfig);
        spinnerRef.current?.append(tempSpinner.domElement);
        setSpinner(tempSpinner);
        setTimeout(resizeEmulate);
      });

      if (spinner) {
        spinner.dispose();
      }

      // @ts-ignore
      document[randomSpinnerId] = tempSpinner;

      setListOfStops(spinnerData.stops || []);
    }
  }, [spinnerElements, spinnerData, randomSpinnerId, currentSpinner, fsUtils]);

  useEffect(() => {
    if (spinner) {
      spinner.on('frames-load', () => {
        const placementStopFrame = detectBestStopFrame();
        if (placementStopFrame && placementStopFrame !== 'undefined') {
          const targetFrame = spinner.frames[0][placementStopFrame];
          targetFrame.get().then(() => {
            setTimeout(() => {
              spinner.controls.setRotateTo([Number(placementStopFrame), 0], true);
            }, 1000);
          });
        }
      });
    }
  }, [spinner]);

  useEffect(() => {
    if (spinner) {
      let mx: any;
      let my: any;
      let touchstart = false;
      let lastZoom = 1;

      const {
        spinnerCanvas,
        spinnerPlace,
        spinnerControl,
        turnLeft,
        turnRight,
        spinnerZoom,
        spinnerUnZoom,
        windRose,
      } = spinnerElements;

      const touchend = () => {
        touchstart = false;
        lastZoom = 1;
      };

      canvasResize();
      window.addEventListener('resize', handleWindowResize);
      spinner.addEventListener('first-frame-load', () => {
        if (spinnerZoom) spinnerZoom.classList.remove('disabled');

        canvasResize();
      });

      spinner.addEventListener('rotateready', () => {
        handleResizeForSvg();
      });

      spinner.on('framezoom', function (this: any) {
        // eslint-disable-next-line no-unused-expressions
        if (this._size.zoom >= this.maxZoom) spinnerZoom?.classList.add('disabled');
        // eslint-disable-next-line no-unused-expressions
        else spinnerZoom?.classList.remove('disabled');

        // eslint-disable-next-line no-unused-expressions
        if (this._size.zoom <= 1) spinnerUnZoom?.classList.add('disabled');
        // eslint-disable-next-line no-unused-expressions
        else spinnerUnZoom?.classList.remove('disabled');
      });

      if (spinnerCanvas) {
        spinnerCanvas.addEventListener('mousemove', (e) => {
          if (e.buttons !== 1) return;

          handleResizeForSvg();
        });
      }

      if (turnLeft) { turnLeft.addEventListener('click', control); }
      if (turnRight) { turnRight.addEventListener('click', control); }
      if (spinnerZoom) { spinnerZoom.addEventListener('click', control); }
      if (spinnerUnZoom) { spinnerUnZoom.addEventListener('click', control); }

      // document.addEventListener('keydown', ({ key }: any) => handleKeyRotate(key));

      if (spinnerPlace) {
        spinnerPlace.style.touchAction = 'none';

        spinnerPlace.addEventListener('touchend', touchend);
        spinnerPlace.addEventListener('touchleave', touchend);

        spinnerPlace.addEventListener('touchstart', (e) => {
          if (touchstart) return;

          mx = e.touches[0].pageX;
          my = e.touches[0].pageY;

          touchstart = true;
        });

        spinnerPlace.addEventListener('touchmove', (e) => {
          if (!touchstart) return;

          const x = e.touches[0].pageX;
          const y = e.touches[0].pageY;

          mx = x;
          my = y;

          // @ts-ignore
          spinner.zoomFrame((e.scale - lastZoom));
          // @ts-ignore
          lastZoom = e.scale;

          handleResizeForSvg();
        });
      }

      spinner.addEventListener('progress', handleOnProgressFSSpinner);
      spinner.addEventListener('loadend', handleOnLoadEndFSSpinner);
      spinner.addEventListener('first-frame-load', (e: { img: { w: number, h: number } }) => {
        if (spinnerControl) {
          spinnerControl.classList.remove('hide');
        }
        // @ts-ignore
        if (!hideWindRose) {
          windRoseRotate(spinner);
          // @ts-ignores
          if (windRose) {
            windRose.classList.remove('hide');
          }
        }
        handleSetStopFrame();
        setSvgSize({ w: spinner.viewBox.width, h: spinner.viewBox.height });
      });
      if (!hideWindRose) {
        spinner.addEventListener('camera-rotate', (event: IRotateEvent) => windRoseRotate(event));
      }

      // SVG
      spinner.addEventListener('rotate-start', () => {
        handleSetStopFrame(true);
      });
      spinner.addEventListener('rotate-end', () => {
        handleResizeForSvg();
      });
    }

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      if (spinner) {
        spinner.dispose && spinner.dispose();
        // @ts-ignore
        document[randomSpinnerId] = null;
        const {
          spinnerCanvas,
          spinnerPlace,
          spinnerControl,
          turnLeft,
          turnRight,
          spinnerZoom,
          spinnerUnZoom,
        } = spinnerElements;
        if (turnLeft) { turnLeft.removeEventListener('click', control); }
        if (turnRight) { turnRight.removeEventListener('click', control); }
        if (spinnerZoom) { spinnerZoom.removeEventListener('click', control); }
        if (spinnerUnZoom) { spinnerUnZoom.removeEventListener('click', control); }
      }
    };
  }, [spinner]);

  const fetchConfig = async () => {
    if (spinnerData && spinner && currentSpinner && fsUtils) {
      const config = await fsUtils.mergeConfig(
        { ...spinnerData, objectFit }, type || currentSpinner.name, spinnerData.additional_data.id,
      );
      fsUtils.loadSpinnerConfig(spinner, config);

      if (initWindRoseData) {
        // fsUtils.loadSpinnerConfig(spinner, { ...config, startFrame: parseInt(spinner.controls.frame.x, 10) });
        initWindRoseData(config._windRose);
      }
    }
  };

  useEffect(() => {
    fetchConfig();
  }, [spinner, spinnerData, currentSpinner, fsUtils]);
  return {
    listOfStops,
    svgSize,
    devicePixelRatio,
    spinnerState,
    spinner,
    fsUtils,
  };
};
