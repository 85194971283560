/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Loader } from 'src/components/Loader';
import { translations } from 'src/constants/translations';
import { pathList } from 'src/containers/Profile/routeList';
import { useGetDevelopers, useGetAuthData } from 'src/hooks';
import { IDeveloper } from 'src/typings/complexes';
import { borderColor } from 'src/constants/styles';
import { deleteDeveloperData } from 'src/api';
import { DevModal } from './components/DevModal';
import { useStyles } from './styles';

export const Developers = () => {
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [editedDev, setEditedDev] = useState<IDeveloper|null>(null);
  const styles = useStyles({});
  // const { getJWT } = useGetAuthData();
  // const jwt = getJWT();
  const { isLoading, developers, fetchDevelopers } = useGetDevelopers();
  const goToDeveloper = (id: string|number) => {
    history.push(`/profile/${pathList.developers}/${id}`);
    // deleteDeveloperData(jwt, id);
  };

  const handleAddDev = () => {
    setEditedDev(null);
    setModalOpen(true);
  };

  if (isLoading) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }

  return (
    <Box py={5} px={4}>
      <Box fontSize="32px" mb={1} fontWeight={700}>
        { translations.developersLink }
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Box
            className={styles.developerHover}
            border={`1px solid ${borderColor}`}
            borderRadius="3px"
            p={2}
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            onClick={handleAddDev}
            height="100%"
          >
            <Box
              width="100px"
              height="84px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
            >
              <AddRoundedIcon style={{ fontSize: '70px' }} />
            </Box>
            { translations.addDeveloper }
          </Box>
        </Grid>
        {developers.map((developer) => (
          <Grid item xs={2} key={developer['@id']}>
            <Box
              className={styles.developerHover}
              border={`1px solid ${borderColor}`}
              borderRadius="3px"
              p={2}
              key={developer['@id']}
              display="flex"
              flexDirection="column"
              alignItems="center"
              onClick={() => goToDeveloper(developer.id)}
              height="100%"
            >
              <Box
                width="100px"
                height="100px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
              >
                <img
                  style={{
                    maxHeight: '100%',
                    maxWidth: '100%',
                  }}
                  src={developer.logoUri}
                  alt={developer.title}
                />
              </Box>
              {developer.title}
            </Box>
          </Grid>
        ))}
      </Grid>
      {modalOpen && (
        <DevModal
          isOpen={modalOpen}
          handleChangeOpen={setModalOpen}
          refreshDevelopers={fetchDevelopers}
          developer={editedDev}
        />
      )}
    </Box>
  );
};
