import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { View2dType } from 'src/typings/view-2d';
import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { Actions } from 'src/store/app/actions';
import { getCrmData, getIsOpenAppartSidebar, getSelectedAppartment } from 'src/store/app/selectors';
import { getView2dSelectedTypes } from 'src/store/view-2d/selectors';
import { Actions as FloorsActions } from 'src/store/floors/actions';
import { useBuildType, useGetFilteredData, useGetFilterParams } from 'src/hooks';
import { shadeColor } from 'src/helpers';

import { useContrastRatio } from 'src/hooks/useContrastRatio';
import { useSelectedDeal } from 'src/hooks/useSelectedDeal';
import { useSelectedIds } from './hooks';
import { useStyles } from './styles';
import { useIsPlacementNeedConnect } from './hooks/useIsPlacementNeedConnect';

interface IFloorItem {
  fillFloorsItem: IFilterAllData;
}

function getFirstLetters(str: string) {
  const firstLetters = str
    .split(' ')
    .map((word) => word[0])
    .join('');

  return firstLetters;
}

export const FloorItem: React.FC<IFloorItem> = React.memo(({
  fillFloorsItem,
}) => {
  const dispatch = useDispatch();
  const { isCrossTable } = useBuildType();
  const { selectedIds } = useSelectedIds();
  const { getColorByBgColor } = useContrastRatio();
  const isSelected = selectedIds.includes(fillFloorsItem.id);
  const crmData = useSelector(getCrmData);
  const [apartmentIsBlocked, setApartmentIsBlocked] = useState<boolean>(false);
  const { selectedHideParams, selectedParams, filterParams } = useGetFilterParams(3);
  const { resultFiltering } = useGetFilteredData();

  const isPlacementNeedConnect = useIsPlacementNeedConnect(fillFloorsItem);
  const { isSelectedDeal } = useSelectedDeal();
  const view2dSelectedType = useSelector(getView2dSelectedTypes);
  const selectedAppartment = useSelector(getSelectedAppartment);
  const isOpenAppartSidebar = useSelector(getIsOpenAppartSidebar);

  const isOpen = useMemo(() => {
    return isOpenAppartSidebar && selectedAppartment == fillFloorsItem[ListOfKeysFilterAllData.id];
  }, [selectedAppartment, isOpenAppartSidebar]);

  const selectedDeal = useMemo(() => {
    return isSelectedDeal(fillFloorsItem);
  }, [fillFloorsItem, crmData]);

  const id = (isOpen)
    ? 'floorItemPopover' : undefined;
  let floorItemText: String | null = '';

  const bgColor = useMemo(() => {
    return isCrossTable
      ? (fillFloorsItem?.[ListOfKeysFilterAllData.placementStatusCrossTableColor]?.toString()
        || fillFloorsItem?.[ListOfKeysFilterAllData.placementStatusColor]?.toString() || '')
      : (fillFloorsItem?.[ListOfKeysFilterAllData.placementStatusColor]?.toString() || '');
  }, [isCrossTable, fillFloorsItem]);

  switch (view2dSelectedType) {
    case View2dType.area:
      floorItemText = fillFloorsItem[ListOfKeysFilterAllData.placementTotalArea];
      break;
    case View2dType.rooms:
      floorItemText = fillFloorsItem[ListOfKeysFilterAllData.placementRooms];
      break;
    case View2dType.numeration:
      floorItemText = fillFloorsItem[ListOfKeysFilterAllData.placementNumeration];
      break;
    case View2dType.price: {
      if (!isCrossTable && fillFloorsItem[ListOfKeysFilterAllData.placementStatusShowInModule] === 'hide') {
        floorItemText = '';
        break;
      }
      const rounded = Math.round(Number(fillFloorsItem[ListOfKeysFilterAllData.placementTotalPrice]) / 1000);
      floorItemText = rounded ? `${rounded}k` : '';
      break;
    }
    case View2dType.pricePM: {
      if (!isCrossTable && fillFloorsItem[ListOfKeysFilterAllData.placementStatusShowInModule] === 'hide') {
        floorItemText = '';
        break;
      }
      const price = Number(Number(fillFloorsItem[ListOfKeysFilterAllData.placementPricePerSqM]).toFixed(0));
      floorItemText = price ? `${price}` : '';
      break;
    }
    default:
      break;
  }

  const styles = useStyles({
    floors: fillFloorsItem[ListOfKeysFilterAllData.placementFloor].length,
    bgColor,
    fontSize: `calc(${floorItemText && floorItemText?.length >= 4 ? '10px' : '17px'} * var(--crosstable-size))`,
    colorByBgColor: getColorByBgColor(bgColor),
  });

  useEffect(() => {
    setApartmentIsBlocked(((!isSelected && fillFloorsItem[ListOfKeysFilterAllData.placementStatusShowInModule] !== 'hide')
      || (!isSelected && fillFloorsItem[ListOfKeysFilterAllData.placementStatusShowInModule] === 'hide' && selectedHideParams.length > 0)
      || !resultFiltering.length));
  }, [isSelected, selectedHideParams, resultFiltering]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isSelected && !isCrossTable) return;

    if (isOpen) {
      dispatch(Actions.setIsOpenAppartSidebar(false));
      dispatch(FloorsActions.setSelectedApartmentId(undefined));
    } else {
      dispatch(FloorsActions.setSelectedApartmentId(fillFloorsItem[ListOfKeysFilterAllData.id]));
      dispatch(Actions.selectAppartment(fillFloorsItem[ListOfKeysFilterAllData.id]));
      dispatch(Actions.setIsOpenAppartSidebar(true));
    }
  };

  return (
    <div className={styles.sectionContentWrapper}>
      <Box
        aria-describedby={id}
        className={`${styles.sectionContentItem} ${apartmentIsBlocked && 'blockedItem'} ${selectedDeal && styles.selectedDeal}`}
        data-floors={ListOfKeysFilterAllData.placementFloor}
        position="relative"
        onClick={handleClick}
      >
        {isPlacementNeedConnect && (
        <Box
          position="absolute"
          bgcolor="#fff"
          borderRadius={50}
          top="2px"
          right="2px"
          style={{ width: 'calc(5px * var(--crosstable-size))', height: 'calc(5px * var(--crosstable-size))' }}
        />
        )}
        {fillFloorsItem[ListOfKeysFilterAllData.placementAdditionalCondition] && (
        <Tooltip
          placement="top"
          TransitionComponent={Zoom}
          arrow
          title={fillFloorsItem[ListOfKeysFilterAllData.placementAdditionalCondition] || ''}
        >
          <Box
            className={styles.additionalCondition}
            top="-1px"
            right="-1px"
            bgcolor={bgColor && shadeColor(bgColor, 60)}
          >
            {getFirstLetters(fillFloorsItem[ListOfKeysFilterAllData.placementAdditionalCondition] || '')}
          </Box>
        </Tooltip>
        )}
        <span className={styles.sectionContentText}>
          {floorItemText}
        </span>
      </Box>
    </div>
  );
});
