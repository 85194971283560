import React, { useEffect, useState } from 'react';
import {
  ICrosstableContentTable,
} from 'src/components/CrosstableContentTable/types';
import { ListOfKeysFilterAllData } from 'src/typings/filter';
import {
  DataGridProps, GridColDef, GridRowParams,
} from '@mui/x-data-grid';
import {
  useBuildType, useGetFilteredData, useTranslations,
} from 'src/hooks';
import { useSelectedIds } from 'src/components/CrosstableContent/components/Section/hooks';
import { AppTable } from 'src/components/AppTable';
import { useTable } from 'src/hooks/table/useTable';
import { GridColumnVisibilityModel } from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces';
import { usePlacementTypeViews } from 'src/hooks/table/usePlacementTypeViews';
import { Actions } from 'src/store/app/actions';
import { Actions as FloorsActions } from 'src/store/floors/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getIsOpenAppartSidebar, getSelectedAppartment } from 'src/store/app/selectors';
import { Stack } from '@mui/material';
import { locals } from 'src/constants';
import { useStyles } from './styles';

export const CrosstableContentTable: React.FC<ICrosstableContentTable> = ({ view2dDataList }) => {
  const styles = useStyles();
  const { getTranslations } = useTranslations();
  const { selectedIds } = useSelectedIds();
  const { resultFiltering } = useGetFilteredData();
  const selectedAppartment = useSelector(getSelectedAppartment);
  const isOpenAppartSidebar = useSelector(getIsOpenAppartSidebar);
  const [isOpen, setIsOpen] = useState<Boolean>(false);
  const {
    apiRef,
    rows,
    columns,
    setRows,
    setColumns,
  } = useTable();
  const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel | undefined>();
  const { isCrossTable } = useBuildType();
  const {
    currentListSubView,
    complexPlacementProperties,
  } = usePlacementTypeViews();
  const dispatch = useDispatch();

  useEffect(() => {
    if (view2dDataList?.length && complexPlacementProperties) {
      const apartmentFields: string[] = [];
      const tempVisibilityModel: {[key: string]: boolean} = {};
      if (isCrossTable) {
        const list = complexPlacementProperties.map((item) => {
          const found = currentListSubView?.propertySettings.find((setting) => setting.placementProperty.oldSystemName === item.oldSystemName);
          return {
            ...item,
            sortOrder: found ? found.sortOrder : -1,
          };
        });
        list.sort((a, b) => a.sortOrder - b.sortOrder)
          .forEach((placementSetting) => {
            const foundPlacementProp = currentListSubView?.propertySettings?.find((prop) => prop.placementProperty.oldSystemName === placementSetting.oldSystemName);
            if (placementSetting.oldSystemName) {
              apartmentFields.push(`${placementSetting.oldSystemName}`);
            }
            if (!foundPlacementProp) {
              tempVisibilityModel[`${placementSetting.oldSystemName}`] = false;
            }
          });
      } else {
        currentListSubView?.propertySettings.forEach((placementSetting) => {
          apartmentFields.push(`${placementSetting.placementProperty.oldSystemName}`);
        });
      }
      const tempRows = !resultFiltering.length ? [] : view2dDataList.filter((item) => selectedIds.includes(item[ListOfKeysFilterAllData.id]));
      if (!visibilityModel) {
        setVisibilityModel(tempVisibilityModel);
      }
      const tempColumns: GridColDef[] = apartmentFields.map((field) => ({
        field,
        headerName: getTranslations(field), // @ts-ignore: next-line
        type: tempRows.every((item) => Number.isNaN(Number(item[field]))) ? 'string' : 'number',
      }));
      setColumns(tempColumns);
      setRows(tempRows);
    }
  }, [view2dDataList, currentListSubView, complexPlacementProperties]);

  useEffect(() => {
    if (isOpenAppartSidebar && selectedAppartment && !selectedIds.includes(selectedAppartment)) {
      setIsOpen(true);
    } else if (isOpen) {
      setIsOpen(false);
    }
  }, [selectedAppartment, isOpenAppartSidebar]);

  const handleClick = (gridRowParams: GridRowParams) => {
    if (isOpen) {
      dispatch(Actions.setIsOpenAppartSidebar(false));
      dispatch(FloorsActions.setSelectedApartmentId(undefined));
    } else {
      dispatch(FloorsActions.setSelectedApartmentId(String(gridRowParams.id)));
      dispatch(Actions.selectAppartment(String(gridRowParams.id)));
      dispatch(Actions.setIsOpenAppartSidebar(true));
    }
  };

  const handleColumnVisibilityChange = React.useCallback<NonNullable<DataGridProps['onColumnVisibilityModelChange']>>((newModel) => {
    setVisibilityModel(newModel);
  }, []);

  function noRowsOverlayFunc(props: any) {
    return (
      <>
        <Stack height="100%" alignItems="center" justifyContent="center">
          {getTranslations(locals.appTranslations.appNoDataToDisplay)}
        </Stack>
      </>
    );
  }

  return (
    <div id="crossTableContent" className={styles.tableWrapper}>
      {apiRef?.current && columns && rows && visibilityModel && (
        <div className={styles.table}>
          <AppTable
            slots={{
              noRowsOverlay: noRowsOverlayFunc,
            }}
            apiRef={apiRef}
            columnVisibilityModel={visibilityModel}
            onColumnVisibilityModelChange={handleColumnVisibilityChange}
            disableColumnFilter
            disableColumnMenu={!isCrossTable}
            columns={columns}
            rows={rows}
            onRowClick={(e) => handleClick(e)}
          />
        </div>
      ) }
    </div>
  );
};
