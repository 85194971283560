import React, {
  Ref, useEffect, useRef, useState,
} from 'react';
import IntersectionVisible from 'react-intersection-visible';
import { useHistory, useParams } from 'react-router-dom';

import {
  AppFullScreenContainer, Loader,
} from 'src/components';
import {
  useBuildType, useFavoriteListState, useGetComplex,
  useSetDocumentTitle,
  useTranslations,
} from 'src/hooks';
import { locals } from 'src/constants/locals';
import { ListOfKeysFilterAllData, PlacementShowModule } from 'src/typings/filter';
import { CreatePdf } from 'src/components/ApartmentPageV2/TabComponent/components/CreatePdf';
import Box from '@mui/material/Box';
import {
  Hidden, Theme,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { ApartmentTitle } from 'src/components/ApartmentPageV2/ApartmentTitle';
import { ApartmentTitleMobile } from 'src/components/ApartmentPageV2/ApartmentTitle/ApartmentTitleMobile';
import {
  ReactImageGalleryItemWithSpinnerData,
} from 'src/components/ApartmentPageV2/ApartmentGallery/types';
import { ApartmentGallery } from 'src/components/ApartmentPageV2/ApartmentGallery';
import { ButtonApp } from 'src/components/ApartmentPageV2/Button';
import { AppHeader } from 'src/components/ApartmentPageV2/AppHeader';
import { AppBreadcrumbs } from 'src/components/ApartmentPageV2/AppBreadcrumbs';
import { ApartmentDescription } from 'src/components/ApartmentPageV2/ApartmentDescription';
import { useSelector } from 'react-redux';
import { getPlacement } from 'src/store/placement/selector';
import { getPlacementViewConfig } from 'src/store/placementViewConfig/selector';
import { UtilityButtonTypeEnum } from 'src/typings/viewConfig';
import { useTheme } from '@mui/styles';
import { SvgHeartSmall } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgHeartSmall';
import { CopyToClipboardButton } from 'src/components/CopyToClipboardButton';
import { useViewConfig } from 'src/hooks/useViewConfig';
import { useGetMappedPDFPaymentPlans } from 'src/hooks/useGetMappedPDFPaymentPlans';
import { PDFDownload } from 'src/components/modals/PDFDownload';
import { getComplexId } from 'src/store/app/selectors';
import { useDisplayApartmentIds } from 'src/hooks/useDisplayApartmentIds';
import { useGetParamsData } from './hooks';
import { useStyles } from './styles';

export const MainPageApartmentsV2: React.FC = () => {
  const { Id }: { Id: string } = useParams();
  const apartmentsTheme: Theme = useTheme();
  const styles = useStyles();
  const { displayIds } = useDisplayApartmentIds();
  const {
    isSingleId,
    spinnerApartments,
    panoApartments,
    chooseData,
  } = useGetParamsData();
  const { getTranslations } = useTranslations();
  const { isCrossTable } = useBuildType();
  useSetDocumentTitle(getTranslations(locals.appTranslations.appTitlePlacement));
  const [showMobileTitle, setShowMobileTitle] = useState<boolean>(false);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [isApartmentAvailable, setIsApartmentAvailable] = useState<boolean>(false);
  const {
    isActive,
    toggleFavoriteItem,
  } = useFavoriteListState(displayIds[0]);
  const elRef: Ref<HTMLDivElement> | null = useRef(null);
  const history = useHistory();
  const { getViewConfig } = useViewConfig();
  const { mappedPDFPaymentPlans, getMappedPDFPaymentPlans } = useGetMappedPDFPaymentPlans();
  const [openPDFPaymentPlansModal, setOpenPDFPaymentPlansModal] = useState<boolean>(false);
  const [selectedPDFPaymentPlan, setSelectedPDFPaymentPlan] = useState<any>(null);
  const [roomTours, setRoomTours] = useState<ReactImageGalleryItemWithSpinnerData[]>([]);

  const onHide = (entries: any) => {
    setShowMobileTitle(true);
    setContainerHeight(elRef.current?.offsetHeight || 0);
  };

  const onShow = (entries: any) => {
    setShowMobileTitle(false);
    setContainerHeight(elRef.current?.offsetHeight || 0);
  };

  const placement = useSelector(getPlacement);
  const placementViewConfig = useSelector(getPlacementViewConfig);
  const complexId = useSelector(getComplexId);
  const complex = useGetComplex(String(complexId), false);

  useEffect(() => {
    if (chooseData) {
      getViewConfig(Id);
      getMappedPDFPaymentPlans(Id, isCrossTable ? 'crosstable' : 'module');
      setIsApartmentAvailable((isCrossTable || chooseData[ListOfKeysFilterAllData.placementStatusShowInModule] === PlacementShowModule.show || !isSingleId));
    }
  }, [chooseData]);

  if (!placementViewConfig || !placement) {
    return (
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb={4}
      >
        <Loader />
      </Box>
    );
  }

  return (
    <AppFullScreenContainer>
      <div className={styles.apartmentWrapper}>
        <div className={styles.headerWrapperMobile}>
          <Hidden mdUp>
            <ButtonApp
              startIcon={<ArrowBackIosNewIcon />}
              className={styles.headerButton}
              onClick={(e) => {
                history.push('/');
              }}
            />
          </Hidden>
          <AppHeader />
        </div>
        <Hidden mdDown>
          <>
            <div className={styles.headerBlock}>
              <AppBreadcrumbs />
              { isApartmentAvailable && (
              <Box className={styles.actions}>
                {placementViewConfig && placementViewConfig.headerSection?.utilityButtons.map((button) => {
                  if (button.applicationProperty.systemName === UtilityButtonTypeEnum.downloadPDF && !button.isHidden) {
                    return (
                      <Box key={button.id} className={styles.actionsItem} style={{ padding: '0' }}>
                        <CreatePdf
                          title={button.applicationProperty.translates?.[placementViewConfig.lang] || 'Download PDF'}
                          placement={chooseData}
                          id={displayIds[0]}
                          circularProgressSize={14}
                          complex={complex}
                          onCustomPDF={() => {
                            const customPdfLink = chooseData?.[ListOfKeysFilterAllData.placementPdf]?.[0] || '';
                            if (customPdfLink) {
                              window.open(customPdfLink, 'blank');
                            }
                          }}
                        />
                      </Box>
                    );
                  }
                  if (button.applicationProperty.systemName === UtilityButtonTypeEnum.separatedPlacementPlans && !button.isHidden) {
                    return (
                      <Box key={button.id} className={styles.actionsItem} style={{ padding: '0' }}>
                        <CreatePdf
                          title={button.applicationProperty.translates?.[placementViewConfig.lang] || 'Download PDF'}
                          placement={chooseData}
                          id={displayIds[0]}
                          isPaymentPlanBtn
                          isLoading={(loading) => {
                            if (!loading) {
                              setSelectedPDFPaymentPlan(null);
                            }
                          }}
                          selectedPaymentPlans={selectedPDFPaymentPlan}
                          openPaymentPlansModal={(state) => {
                            setOpenPDFPaymentPlansModal(state);
                          }}
                          circularProgressSize={14}
                        />
                      </Box>
                    );
                  }
                  if (button.applicationProperty.systemName === UtilityButtonTypeEnum.copyLink && !button.isHidden) {
                    return (
                      <CopyToClipboardButton key={button.id} title={button.applicationProperty.translates?.[placementViewConfig.lang] || 'Copy'} />
                    );
                  }
                  if (button.applicationProperty.systemName === UtilityButtonTypeEnum.addToFavorites && !button.isHidden) {
                    return (
                      <ButtonApp
                        isCapitalize
                        key={button.id}
                        className={`${styles.actionsItem}`}
                        onClick={toggleFavoriteItem}
                      >
                        <SvgHeartSmall fill={isActive ? apartmentsTheme.palette.primary.main : 'none'} />
                        <div>
                          {button.applicationProperty.translates?.[placementViewConfig.lang] || 'Save'}
                        </div>
                      </ButtonApp>
                    );
                  }
                  return (<div key={button.id} />);
                })}
              </Box>
              )}
            </div>
          </>
        </Hidden>

        {chooseData && (
          <>
            {
              (isApartmentAvailable)
                ? (
                  <div
                    className={styles.apartmentContainer}
                    style={{
                      marginBottom: `${containerHeight - 27}px`,
                    }}
                  >
                    <Hidden mdUp>
                      <IntersectionVisible
                        onHide={(e: Event) => onHide(e)}
                        onShow={(e: Event) => onShow(e)}
                      >
                        <ApartmentTitle
                          viewConfig={placementViewConfig}
                        />
                        <ApartmentTitleMobile
                          ref={elRef}
                          showTitle={showMobileTitle}
                          viewConfig={placementViewConfig}
                          showCallToActionBtn={!placementViewConfig.headerSection?.callToAction.isHidden}
                          showRedirectBtn={!placementViewConfig.headerSection?.unitPageRedirect.isHidden}
                          placeInfo={chooseData}
                        />
                      </IntersectionVisible>
                    </Hidden>
                    <ApartmentGallery
                      combineIds={displayIds}
                      spinnerApartments={spinnerApartments}
                      panoApartments={panoApartments}
                      gallery={chooseData[ListOfKeysFilterAllData.placementGallery] || []}
                      chooseData={chooseData}
                      isSingleId={isSingleId}
                      setRoomTours={setRoomTours}
                      placementViewConfig={placementViewConfig}
                    />
                    <ApartmentDescription
                      isSingleId={isSingleId}
                      placeInfo={chooseData}
                      combineIds={displayIds}
                      spinnerApartments={spinnerApartments}
                      panoApartments={panoApartments}
                      gallery={chooseData[ListOfKeysFilterAllData.placementGallery] || []}
                      roomTours={roomTours}
                      placementViewConfig={placementViewConfig}
                    />
                  </div>
                )
                : (
                  <div>
                    <p className={styles.textNotAvailable}>{getTranslations(locals.appTranslations.appApartmentNotAvailable)}</p>
                  </div>
                )
            }
          </>
        )}
        {mappedPDFPaymentPlans && (
          <PDFDownload
            open={openPDFPaymentPlansModal}
            onClose={() => setOpenPDFPaymentPlansModal(false)}
            isLoading={selectedPDFPaymentPlan !== null}
            plansList={mappedPDFPaymentPlans}
            onSelectPlan={setSelectedPDFPaymentPlan}
          />
        )}
      </div>
    </AppFullScreenContainer>
  );
};
