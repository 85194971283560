/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react';

import { useFavoriteStorage } from 'src/hooks/app';
import { useStyles } from './styles';

export const AppFavoriteIcon: React.FC = () => {
  const styles = useStyles({});
  const { storeFavCount, isUp } = useFavoriteStorage();

  return (
    <>
      <svg height="0" width="0">
        <defs>
          <clipPath id="svgPath">
            <path
              d="M20,35.09,4.55,19.64a8.5,8.5,0,0,1-.13-12l.13-.13a8.72,8.72,0,0,1,12.14,0L20,10.79l3.3-3.3a8.09,8.09,0,0,1,5.83-2.58,8.89,8.89,0,0,1,6.31,2.58,8.5,8.5,0,0,1,.13,12l-.13.13Z"
            />
          </clipPath>
        </defs>
      </svg>

      <div
        className={`${styles.heartContainer} ${
          isUp === null
            ? ''
            : isUp
              ? styles.heartContainerUp
              : styles.heartContainerDown}
        `}
      >
        <svg width="24" height="24" viewBox="0 0 40 40" className={styles.heartStyles}>
          <path
            d="M20,35.07,4.55,19.62a8.5,8.5,0,0,1-.12-12l.12-.12a8.72,8.72,0,0,1,12.14,0L20,10.77l3.3-3.3A8.09,8.09,0,0,1,29.13,4.9a8.89,8.89,0,0,1,6.31,2.58,8.5,8.5,0,0,1,.12,12l-.12.12ZM10.64,7.13A6.44,6.44,0,0,0,6.07,18.19L20,32.06,33.94,18.12A6.44,6.44,0,0,0,34,9l0,0a6.44,6.44,0,0,0-4.77-1.85A6,6,0,0,0,24.83,9L20,13.78,15.21,9A6.44,6.44,0,0,0,10.64,7.13Z"
          />
        </svg>

        <div className={`${styles.heartClip} ${storeFavCount ? styles.heartClipFilled : ''}`} />
        {!!storeFavCount && storeFavCount !== 0 && (
          <>
            <div className={styles.wrapperFavoriteCount}>
              <span>{ `${storeFavCount > 9 ? '9+' : storeFavCount}` }</span>
            </div>
          </>
        ) }
      </div>
    </>
  );
};
