import React, {
  useMemo, useState, useEffect, useRef,
} from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getIsRerender } from 'src/store/view-2d/selectors';
import { MemoizedRenderSvg } from 'src/components/ApartmentPageV2/ViewSpinner/components/RenderSvg';
import { Delayed } from 'src/components/Delayed';
import { WindRose } from 'src/components/ApartmentPageV2/WindRose';
import { randomHash } from 'src/helpers';
import { useAppVisibilitySpinnerBtn } from 'src/hooks/app';
import { makeWindRoseData } from 'src/components/ApartmentPageV2/WindRose/state';
import { PopupProvider } from 'src/components/ApartmentPageV2/ViewSpinner/context';
import { AppPopover } from 'src/components/ApartmentPageV2/AppPopover';
import { ListOfKeysFilterAllData } from 'src/typings/filter';
import { requestSelectors } from 'src/store/requests/selectors';
import { useGetFilteredIds } from 'src/components/ApartmentPageV2/ViewSpinner/components/RenderSvg/hooks';
import { useBuildType } from 'src/hooks';
import { usePolygonConfig } from 'src/components/ViewSpinner/components/RenderSvg/hooks';
import { getSpinnerDetailInfo } from 'src/store/requests/spinner-views/selector';
import { ISpinnerFitType } from 'src/typings/spinner';
import { getAppWindRose } from 'src/store/spinner/selectors';
import { useDisplayApartmentIds } from 'src/hooks/useDisplayApartmentIds';
import { useStyles } from './styles';
import { useInitSpinner, useGetSpinnerSvg } from './hooks';
import { IAppSpinner } from './types';

export const AppSpinner: React.FC<IAppSpinner> = React.memo(({
  hideControls,
  spinnerData,
  roseInTop,
  type,
  spinnerHeight,
  hideWindRose = false,
  hideFloor = false,
  objectFit = ISpinnerFitType.cover,
  wrapperClassName = '',
  onWindRoseOnload,
  onClick,
}) => {
  const randomSpinnerId: string = useMemo(randomHash, []);
  const theme: Theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isNotLarge = useMediaQuery('(max-width:1280px)');
  const styles = useStyles({});
  const isRerender = useSelector(getIsRerender);
  const [initWindRoseData, setInitWindRoseData] = useState<Function | null>(null);
  const spinnerRef = useRef<HTMLDivElement>();
  const { globalPolygonConfig } = usePolygonConfig();
  const detailInfo = useSelector(getSpinnerDetailInfo);
  const { isCrossTable } = useBuildType();
  const { filteredIds, getIdsByType } = useGetFilteredIds();
  const appWindRoseConfig = useSelector(getAppWindRose);
  const { gallerySpinnerBtnViews } = useAppVisibilitySpinnerBtn();
  const filteredIdsRef = useRef(filteredIds);
  filteredIdsRef.current = filteredIds;
  const getPopupConfig = useSelector(requestSelectors.app.getPopupConfig);
  const [hideSpinner, setHideSpinner] = useState<boolean>(false);

  // const {
  //   displayedSvgData,
  // } = useGetSpinnerSvg(spinnerData);
  const {
    listOfStops,
    // svgSize,
    spinnerState,
    spinner,
  } = useInitSpinner(
    spinnerData,
    randomSpinnerId,
    hideWindRose,
    type,
    initWindRoseData,
    objectFit,
    spinnerRef,
    appendChildHandler,
  );

  const windRoseConfig = initWindRoseData ? initWindRoseData() : {};

  const isHighlight = (name: string, nameId: string) => {
    // @ts-ignore
    return !!filteredIdsRef.current[name]?.includes(nameId);
  };

  const getStaticBodyColor = (nameId: string) => {
    return ((getPopupConfig?.enable_polygon_status_colors || isCrossTable)
      && detailInfo?.placement?.[nameId]) ? detailInfo?.placement[nameId][isCrossTable ? (
        ListOfKeysFilterAllData.placementStatusCrossTableColor
      ) : ListOfKeysFilterAllData.placementStatusColor] : `${globalPolygonConfig?.static_body_color}`;
  };

  useMemo(() => {
    spinner?.children.forEach((item: any) => {
      if ((filteredIds as string[])?.includes(item.userData['entity-view-name-id'])) {
        item.domElement.setAttribute('stroke', globalPolygonConfig?.static_border_color);
        item.domElement.setAttribute('fill', getStaticBodyColor(item.userData['entity-view-name-id']));
        item.domElement.setAttribute('fill-opacity', globalPolygonConfig?.static_body_opacity);
      } else {
        item.domElement.setAttribute('stroke', 'transparent');
        item.domElement.setAttribute('fill', 'transparent');
        item.domElement.setAttribute('fill-opacity', '0');
      }
    });
  }, [spinner, filteredIds, globalPolygonConfig, filteredIdsRef]);

  function appendChildHandler({ child }: {target: any, child: any}) {
    const { domElement, type: childType } = child;

    const {
      'hover-text': hoverText,
      infrastructure,
      'infrastructure-view-type': infrastructureViewType,
      'entity-view-id': entityViewId,
      'entity-view': entityView,
      'entity-view-name': entityViewName,
      'entity-view-type': entityViewType,
      'entity-view-name-id': entityViewNameId,
      'entity-view-stop': entityViewStop,
      'data-is-terrace': dataIsTerrace,
      'counter-id': counterId,
      'place-counter': placeCounter,
      redirect,
    } = child.userData;

    if (childType === 'area') {
      domElement.setAttribute('cursor', isHighlight(entityViewName, entityViewNameId) ? 'pointer' : 'default');
      domElement.setAttribute('stroke', isHighlight(entityViewName, entityViewNameId) ? globalPolygonConfig?.static_border_color : 'transparent');
      domElement.setAttribute(
        'fill', isHighlight(entityViewName, entityViewNameId) ? detailInfo?.placement?.[entityViewNameId] ? detailInfo?.placement[entityViewNameId][isCrossTable ? (
          ListOfKeysFilterAllData.placementStatusCrossTableColor
        ) : ListOfKeysFilterAllData.placementStatusColor] : `${globalPolygonConfig?.static_body_color}` : 'transparent',
      );
      domElement.setAttribute(
        'fill-opacity',
        globalPolygonConfig?.static_body_opacity,
      );
    }
    child.emit('update-user-data');
  }

  const onWindRoseClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(event);
    }
  };

  useEffect(() => {
    if (!windRoseConfig?.src) {
      setHideSpinner(true);
    } else {
      setHideSpinner(false);
    }
  }, [windRoseConfig, filteredIdsRef]);

  useEffect(() => {
    setInitWindRoseData(makeWindRoseData);
  }, []);

  return (
    <PopupProvider>
      <Box
        className={`${wrapperClassName}`}
        width="100%"
        height="100%"
        position="relative"
        alignItems="center"
        flexDirection="column"
        display={isNotLarge ? 'flex' : 'block'}
      >

        {!hideWindRose && (
        <>
          <Box className="holder" onClick={(event) => onWindRoseClick(event)}>
            <WindRose
              config={windRoseConfig}
              randomSpinnerId={randomSpinnerId}
              onTop={roseInTop}
              isAppSpinner
              onLoad={onWindRoseOnload}
            />
          </Box>
        </>
        )}

        <Box
          hidden={hideFloor}
          id={`spinner_place-v2${randomSpinnerId}`}
          className="spinner_place-v2"
          height={spinnerHeight ? `${spinnerHeight}px !important` : 'dontknow'}
          data-appspinner-id={randomSpinnerId}
        >
          <Box
            id={`spinner_app_canvas${randomSpinnerId}`}
            className={`spinner_app_canvas ${styles.canvasStyle}`}
            ref={spinnerRef}
          />
          {!hideFloor && (
          <>
            { !hideControls && (
            <div id={`spinner_control-v2${randomSpinnerId}`} className={`spinner_control-v2 ${gallerySpinnerBtnViews}`}>
              <div
                className={`turn_left ${listOfStops.length <= 1 ? styles.hideRotate : ''} ${styles.turnBtn}`}
                id={`turn_left${randomSpinnerId}`}
              >
                <i className="material-icons">arrow_back</i>
              </div>
              <div
                className={`turn_right ${listOfStops.length <= 1 ? styles.hideRotate : ''} ${styles.turnBtn}`}
                id={`turn_right${randomSpinnerId}`}
              >
                <i className="material-icons">arrow_forward</i>
              </div>
              <div className="spinner_zoom" id={`spinner_zoom${randomSpinnerId}`}><i className="material-icons">zoom_in</i></div>
              <div className="spinner_unzoom" id={`spinner_unzoom${randomSpinnerId}`}><i className="material-icons">zoom_out</i></div>
            </div>
            )}

            <div id={`porgress_bar${randomSpinnerId}`} className="porgress_bar" />
          </>
          )}

        </Box>
      </Box>
      <AppPopover />
    </PopupProvider>
  );
});
