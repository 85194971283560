import React from 'react';

import { IPlacementInfo } from 'src/components/ListOfLayoutApartments/types';
import { IFilterPlacingInfo } from 'src/typings/filter';
import { useTranslations } from 'src/hooks';
import { useGenerateShowingInfo } from '../../hooks';
import { useStyles } from './styles';

export const InfoPlacing: React.FC<IPlacementInfo> = ({
  itemData,
  filterConfigurator,
  displayedType,
}) => {
  const styles = useStyles({});
  const { getTranslations } = useTranslations();
  const renderItems: { [key: string]: IFilterPlacingInfo | any } = useGenerateShowingInfo(filterConfigurator[displayedType]);

  return (
    <div className={styles.apartmentHeaderInfo}>
      {Object.keys(renderItems).map((renderItem, index) => {
        const renderData = renderItems[renderItem];
        const placementData = Array.isArray(itemData[renderItem]) ? itemData[renderItem].sort((a: string, b: string) => +a - +b) : itemData[renderItem];

        if (itemData[renderItem]) {
          return (
            <div
              key={renderItem}
              className={styles.placingInfo}
            >
              {renderData['use-name'] && `${getTranslations(renderItem)}`}
              {` ${placementData} `}
            </div>
          );
        }

        return null;
      })}
    </div>
  );
};
