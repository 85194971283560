import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  crossTableTitleSpace,
  crossTableBigItemHeight,
} from 'src/constants';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  floorContainer: {
    marginRight: '30px',
    position: 'sticky',
    left: '0px',
    top: '0px',
    zIndex: 6,
    height: 'max-content',
    [theme.breakpoints.down('md')]: {
      marginRight: '0',
    },
  },
  floorTitle: (props: any) => ({
    padding: '4px 0px',
    marginBottom: '45px',
    position: 'sticky',
    top: '0px',
    background: props.filterBackgroundColor,
    textAlign: 'center',
    zIndex: 6,
    whiteSpace: 'nowrap',
    '& > span': {
      fontSize: '17px',
      fontWeight: 'bold',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      height: '20px',
      left: '0px',
      top: '31px',
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      marginBottom: '23px',
      '& > span': {
        fontSize: '12.25px',
        fontWeight: '400',
      },
    },
  }),
  floorListWrapper: {
    minWidth: '50px',
    background: '#F6F7F6',
    border: `1px solid ${theme.palette.grey['300']}`,
    position: 'relative',
    '& > div:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey['300']}`,
    },
  },
  floorItem: (props: any) => ({
    display: 'flex',
    lineHeight: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 'calc(5px * var(--crosstable-size)) 0px',
    outline: 'none',
    minHeight: `calc(${props.isBigFloorItem ? `${crossTableBigItemHeight + 4}px` : '36px * var(--crosstable-size)'})`,
    '& > span': {
      color: theme.palette.secondary.main,
      fontSize: 'calc(17px * var(--crosstable-size))',
      whiteSpace: 'nowrap',
    },
  }),
  appSlickSliderWrapper: {
    position: 'initial',
    '& .slick-slide': {
      borderBottom: `1px solid ${theme.palette.grey['300']}`,
    },
    '& .slick-current': {},
    '& .slick-slider': {
      position: 'initial',
    },
    '& .slick-arrow': {
      border: `1px solid ${theme.palette.grey['300']}`,
      position: 'absolute',
      top: '0',
      left: '-1px',
      minWidth: 'calc(100% + 2px)',
      '& span[class*="startIcon"]': {
        margin: '0px',
        top: '-5px',
        color: '#9E9E9E',
        position: 'relative',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .slick-prev': {
      top: '-10px',
      borderRadius: '5px 5px 0px 0px',
    },
    '& .slick-next': {
      bottom: '-30px',
      borderRadius: '0px 0px 5px 5px',
      top: 'auto',
    },
  },
}));
