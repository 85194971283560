import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState, useEffect } from 'react';

import {
  getListOfTypeDisplayedSpinner,
  getSelectedView,
} from 'src/store/type-displayed-spinner/selectors';
import { Actions } from 'src/store/type-displayed-spinner/actions';
import { Actions as ActionsFloors } from 'src/store/floors/actions';
import { Actions as ActionsPano } from 'src/store/panorama/actions';
import { getMarkups } from 'src/store/requests/spinner-views/selector';
import { TypesOfSpinner } from 'src/typings/types-of-spinner';
import { resizeEmulate } from 'src/helpers';
import { useSetCurrentEntity } from 'src/hooks/app';
import { useSetCurrentSpinner } from 'src/hooks/spinner';
import { useBuildType, useLanguageState } from 'src/hooks';
import { requestSelectors } from 'src/store/requests/selectors';
import { getCurrentSpinner } from 'src/store/spinner/selectors';
import { getSelectedHouse, getFromComplex } from 'src/store/floors/selectors';
import { IFirstSpinner, IMarkUps } from 'src/typings/spinner';
import { locals } from 'src/constants/locals';
import { getSelectedPlacement } from 'src/store/requests/type-of-property/selector';
import { IFrontConfigTypeEnum, IViewType } from 'src/typings/table';
import { IView } from 'src/store/type-displayed-spinner/types';
import { getCrmData } from 'src/store/app/selectors';

export const useTypeDisplayedSpinner = () => {
  const dispatch = useDispatch();
  const [selectedViews, setSelectedViews] = useState<IView[]>([]);
  const { isCrossTable } = useBuildType();
  const { currentAppLanguage } = useLanguageState();
  const selectedPlacementType = useSelector(getSelectedPlacement);
  const { handleSetCurrentSpinner } = useSetCurrentSpinner();
  const { handleSetCurrentEntity } = useSetCurrentEntity();
  const selectedHouse: IFirstSpinner | null = useSelector(getSelectedHouse);
  const fromComplex: boolean = useSelector(getFromComplex);
  const currentSpinner = useSelector(getCurrentSpinner);
  const listDisplayedTypes = useSelector(getListOfTypeDisplayedSpinner);
  const houseMarkups: IMarkUps | any = useSelector(getMarkups);
  const getSelectedViewId = useSelector(getSelectedView);
  const firstSpinnerData = useSelector(requestSelectors.spinner.getSpinnerFirstSpinner);
  const crmData = useSelector(getCrmData);

  const isLayoutView = getSelectedViewId === TypesOfSpinner.viewLayouts;
  const is2dView = getSelectedViewId === TypesOfSpinner.view2d;
  const isFloorsView = getSelectedViewId === TypesOfSpinner.viewFloors;

  useEffect(() => {
    const findedType = listDisplayedTypes.find((type) => {
      if (type.title[currentAppLanguage] === selectedPlacementType) {
        return true;
      }
      return false;
    });
    if (findedType && selectedPlacementType === locals.appTranslations.appInfrastructure) {
      // @ts-ignore
      setSelectedViews(findedType.frontConfig[isCrossTable ? IFrontConfigTypeEnum.adminPanel : IFrontConfigTypeEnum.website].views.filter((view) => [TypesOfSpinner.viewSpinner, TypesOfSpinner.viewFloors].includes(view.type)));
    }
    if (findedType) {
      setSelectedViews(findedType.frontConfig[isCrossTable ? IFrontConfigTypeEnum.adminPanel : IFrontConfigTypeEnum.website].views.filter((view) => view.isEnabled));
    }
  }, [selectedPlacementType, listDisplayedTypes]);

  const handleOnChangeTypeMap = useCallback((typeId: string) => {
    if (typeId !== getSelectedViewId) {
      dispatch(ActionsPano.setLookAtMyPano(false));
      dispatch(Actions.setTypeDisplayedSpinner(typeId));
      if (currentSpinner && currentSpinner.name === 'house') {
        dispatch(ActionsFloors.setFromComplex(false));
        dispatch(ActionsFloors.setSelectedHouse(currentSpinner));
      }
      if (currentSpinner && houseMarkups && currentSpinner.name === 'complex') {
        dispatch(ActionsFloors.setFromComplex(true));
        dispatch(ActionsFloors.setSelectedHouse({
          name: 'house',
          'name-id': Object.keys(houseMarkups.house)[0],
        }));
      }
      setTimeout(resizeEmulate, 100);
    }
  }, [dispatch, getSelectedViewId, currentSpinner]);

  const handleDropToPreviousView = useCallback(() => {
    if (selectedHouse !== null) {
      if (fromComplex && houseMarkups) {
        handleSetCurrentSpinner(
          'complex',
          firstSpinnerData['name-id'],
        );
        handleSetCurrentEntity(
          'complex',
          firstSpinnerData['name-id'],
        );
      } else {
        handleSetCurrentSpinner(
          selectedHouse.name,
          selectedHouse['name-id'],
        );
        handleSetCurrentEntity(
          selectedHouse.name,
          selectedHouse['name-id'],
        );
      }

      if (!crmData) dispatch(ActionsFloors.setInitialReducer());
    }
  }, [dispatch, handleSetCurrentSpinner, handleSetCurrentEntity, selectedHouse, firstSpinnerData, houseMarkups]);

  return {
    isFloorsView,
    isLayoutView,
    is2dView,
    selectedViews,
    getSelectedViewId,
    selectedHouse,
    handleOnChangeTypeMap,
    handleDropToPreviousView,
  };
};
