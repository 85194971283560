import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getFloorItemType } from 'src/store/view-2d/selectors';
import { Actions } from 'src/store/view-2d/actions';
import { Actions as TableActions } from 'src/store/table/actions';
import { EFloorType } from 'src/store/view-2d/reducer';
import { translations } from 'src/constants/translations';
import { useGridApiRef } from '@mui/x-data-grid';
import { IPlacementProperty } from 'src/typings/viewConfig';
import { IVisibleConfig } from 'src/store/type-displayed-spinner/types';
import { Actions as AppActions } from 'src/store/app/actions';
import { useStyles } from './styles';

export const SelectFloorItemType: React.FC<{visibleConfig: IVisibleConfig, placementsReport: IPlacementProperty | undefined, lang: string}> = ({ visibleConfig, placementsReport, lang }) => {
  const styles = useStyles({});
  const dispatch = useDispatch();
  const floorItemType = useSelector(getFloorItemType);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const tableApiRef = useGridApiRef();
  const viewOptions = [
    {
      key: EFloorType.moreInfo,
      text: translations.detailedView,
      value: EFloorType.moreInfo,
      visible: String(visibleConfig.detailedSubView.isEnabled),
      sortOrder: visibleConfig.detailedSubView.sortOrder,
    },
    {
      key: EFloorType.mini,
      text: translations.simpleView,
      value: EFloorType.mini,
      visible: String(visibleConfig.simpleSubView.isEnabled),
      sortOrder: visibleConfig.simpleSubView.sortOrder,
    },
    {
      key: EFloorType.list,
      text: translations.listView,
      value: EFloorType.list,
      visible: String(visibleConfig.listSubView.isEnabled),
      sortOrder: visibleConfig.listSubView.sortOrder,
    },
  ];

  useEffect(() => {
    if (floorItemType === EFloorType.list && tableApiRef.current) {
      dispatch(TableActions.setTableApiRef({ ...tableApiRef }));
    }
  }, [floorItemType]);

  const handleOnChange = (value: any) => {
    if (value !== floorItemType) {
      dispatch(AppActions.setIsOpenAppartSidebar(false));
      dispatch(Actions.setFloorItemType(value));
    }
  };

  return (
    <Box className={styles.options}>
      {placementsReport?.translates?.[lang] ? placementsReport?.translates?.[lang] : placementsReport?.systemName }
      : &nbsp;
      <Box display="flex" gap="5px">
        { viewOptions
          .filter((item) => item.visible === 'true')
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map((view) => {
            return (
              <button
                key={view.key}
                type="button"
                className={`${styles.option} ${floorItemType === view.key ? 'selected' : ''}`}
                onClick={() => handleOnChange(view.value)}
              >
                {view.text}
              </button>
            );
          }) }
      </Box>
    </Box>
  );
};
