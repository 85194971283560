import React from 'react';
import { ReactImageGalleryItemWithSpinnerData } from 'src/components/ApartmentPageV2/ApartmentGallery/types';

export const ApartmentDescriptionTour: React.FC<{tours: ReactImageGalleryItemWithSpinnerData[]}> = ({ tours }) => {
  return (
    <>
      { tours.map((tour) => {
        return (
          <iframe
            key={tour.original}
            title="sometitle"
            src={tour.original}
            className="gallery-playcanvas-v2"
          />
        );
      }) }
    </>
  );
};
