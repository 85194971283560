import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  options: {
    display: 'flex',
    '& .selected': {
      fontWeight: 'bold',
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
  },
  option: {
    padding: '0',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    color: `${theme.palette.primary.main} !important`,
  },
}));
