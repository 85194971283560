import React from 'react';

export const SvgArrow: React.FC = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="1.70711" y1="12.2929" x2="13.0208" y2="23.6066" stroke="currentColor" strokeWidth="2" />
      <line x1="0.999985" y1="13" x2="12.3137" y2="1.68628" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
};
