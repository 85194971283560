import React from 'react';

export const SvgPDF: React.FC = () => {
  return (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 1V3.66667C7.5 3.84348 7.57024 4.01305 7.69526 4.13807C7.82029 4.2631 7.98986 4.33333 8.16667 4.33333H10.8333"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 7V2.33333C1.5 1.97971 1.64048 1.64057 1.89052 1.39052C2.14057 1.14048 2.47971 1 2.83333 1H7.5L10.8333 4.33333V7"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 11H2.5C2.76522 11 3.01957 10.8946 3.20711 10.7071C3.39464 10.5196 3.5 10.2652 3.5 10C3.5 9.73478 3.39464 9.48043 3.20711 9.29289C3.01957 9.10536 2.76522 9 2.5 9H1.5V13"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.5 11H10.8333" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.5 9H9.5V13" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.49976 9V13H6.16642C6.52004 13 6.85918 12.8595 7.10923 12.6095C7.35928 12.3594 7.49976 12.0203 7.49976 11.6667V10.3333C7.49976 9.97971 7.35928 9.64057 7.10923 9.39052C6.85918 9.14048 6.52004 9 6.16642 9H5.49976Z"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

  );
};
