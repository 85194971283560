import { useGetAuthData } from 'src/hooks/use-get-auth-data';
import { getMappedPDFPaymentPlansRequest } from 'src/api/paymentPlan';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentLang } from 'src/store/app/selectors';

export const useGetMappedPDFPaymentPlans = () => {
  const currentLang = useSelector(getCurrentLang);
  const [mappedPDFPaymentPlans, setMappedPDFPaymentPlans] = useState<any[]>([]);

  const getMappedPDFPaymentPlans = async (placementId: string, frontType: string, commonPDFName?: string) => {
    const { data: plans } = await getMappedPDFPaymentPlansRequest(placementId, currentLang, frontType);
    let temp = plans.map((plan: any, index: number) => ({ ...plan, id: index }));
    if (commonPDFName) {
      temp = [{ id: temp.length + 1, name: commonPDFName, type: 'common' }, ...temp];
    }
    setMappedPDFPaymentPlans(temp);
  };
  return {
    mappedPDFPaymentPlans,
    getMappedPDFPaymentPlans,
    setMappedPDFPaymentPlans,
  };
};
