export type IViewType = 'ThreeDSpinner' | 'GroupedPlacementPlanning' | 'FloorPlanning' | 'Map' | 'Crosstable';
export type ISubViewType = 'DetailedSubView' | 'SimpleSubView' | 'ListSubView';
export type IFrontConfigType = 'website' | 'adminPanel';
export enum ExportButton {
  excel = 'excel',
  pdf = 'pdf',
}

export enum IFrontConfigTypeEnum {
  website = 'website',
  adminPanel = 'adminPanel',
}

export interface IExportPdfData {
  projectLogo: string,
  developerLogo: string,
  issuedDate: string,
  showFlatShowLogo: boolean,
  tableFields: string[],
  tableData: {[key: string]: string}[]
}
