import {
  INetHuntFeedbackMessageConfiguration,
  INetHuntFunnel,
} from 'src/components/profileComponents/Integrations/Nethunt/types';
import { IAdminPanel, IFrontConfigBase } from 'src/store/type-displayed-spinner/types';
import { IUsers } from './user';
import { IDField, IDogeFields, IDogeFieldsOptional } from './global-config';

export enum IGtmEventsTypes {
  formSubmit = 'FormSubmitButtonClick',
  pdfDownload = 'DownloadPDFButtonClick',
}
export type IStage = IBitrixStage | ICreatioStage | INetHuntStages;

export interface IBitrixStage extends IDogeFields {
  bitrixCategory: string;
  bitrixDeals: string[];
  bitrixExternalStageId: string;
  bitrixExternalStageName: string;
  id: number;
  priority: number;
  placementStatuses: string[];
}

export interface ICreatioStage extends Omit<IDogeFields, '@context'> {
  id: number;
  creatioProject: string;
  externalId: string;
  externalName: string;
  priority: number;
  sortSequence: number;
  unlinkPlacementAtStageWithLowerPriority: boolean;
  unlinkPlacementAtStageWithHigherPriority: boolean;
  unlinkPlacementAtStageWithSamePriority: boolean;
  unlinkAllPlacementsAtAllOtherStages: boolean;
  unlinkPlacementAtThisStage: boolean;
  blockMoveAndLinkDealsWithPlacementAtOtherStages: boolean;
  blockMoveNdLinkDealsWithPlacementAtOrAftrCurrentStage: boolean;
  placementStatuses: string[];
}

export interface INetHuntStages extends IDogeFieldsOptional {
  externalName: string,
  funnel: string,
  placementStatuses: string[],
  priority: number,
  sortOrder: number,
  unlinkPlacementAtStageWithLowerPriority: boolean,
  unlinkPlacementAtStageWithHigherPriority: boolean,
  unlinkPlacementAtStageWithSamePriority: boolean,
  unlinkAllPlacementsAtAllOtherStages: boolean,
  unlinkPlacementAtThisStage: boolean,
  blockMoveAndLinkDealsWithPlacementAtOtherStages: boolean,
  blockMoveNdLinkDealsWithPlacementAtOrAftrCurrentStage: boolean
}

export interface IStatusOnZeroDeals extends IDogeFields {
  id: number,
  complex: IComplex,
  placementStatus: IStatus
}

export interface ICategory extends IDogeFields {
  bitrixCategoryPlacementStatusOnZeroDeals: IStatusOnZeroDeals[];
  bitrixExternalCategoryName: string;
  bitrixExternalCategoryId: string;
  complexes: IComplex[];
  bitrixStages: string[];
  id: number;
}

export interface ICategoryChild extends IDogeFields {
  bitrixExternalCategoryName: string;
  bitrixExternalCategoryId: string;
  complexes: string[];
  bitrixStages: string[];
}

export interface IProjectFrontDealConfig extends IDogeFields {
  id: number;
  isShowDealDataForManagers: boolean;
}

export interface IProjectFrontConfig extends IDogeFields {
  id: number;
  bitrixProject: string;
  dealConfig: IProjectFrontDealConfig;
}

export interface IProject extends IDogeFields {
  id: number | string;
  domain: string;
  webhookUrl: string;
  workspaceId: string;
  authKey: string;
  authEmail: string;
  formEndpoint: string;
  funnels: INetHuntFunnel[] | [],
  formMessageConfiguration: INetHuntFeedbackMessageConfiguration | null;
  developer: string;
  bitrixCategories: ICategoryChild[];
  login: string;
  password: string;
  oauth2Enpoint: string;
  oauth2ClientId: string;
  oauth2ClientSecret: string;
  creatioStages: IStage[];
  serverIp: string;
  leadEndpointSuffix: string;
  leadSchemaName: 'Lead';
  leadSchemaUid: string;
  opportunityEndpointSuffix: string;
  opportunitySchemaName: 'Opportunity';
  opportunitySchemaUid: string;
  opportunityStageEndpointSuffix: string;
  opportunityStageSchemaName: 'OpportunityStage';
  frontConfig: IProjectFrontConfig;
}

export interface IDeveloper extends IDogeFields {
  id: number | string;
  logoUri: string;
  title: string;
  bitrixProject: string | null;
  creatioProject: string | null;
  netHuntIntegration: string | null;
  complexes: IComplex[];
}

export interface IAmoPipeline extends IDogeFields {
  amoProject: string | null;
  amo_id: number;
  id: number;
}

export interface IGtmEvents extends IDogeFields, IDField {
  eventJson: string,
  type: IGtmEventsTypes,
}

export interface IGtm extends IDogeFields, IDField {
  events: IGtmEvents[],
}

export interface IComplexFrontConfigWebsite extends IFrontConfigBase {
  gtm: IGtm,
}

export interface IComplexFrontConfig extends IDogeFields, IDField {
  adminPanel: IAdminPanel,
  website: IComplexFrontConfigWebsite,
}

export interface IComplex extends IDogeFields {
  amo_pipeline: IAmoPipeline | null;
  bitrixComplexData: null;
  developers: IDeveloper[];
  id: number | string;
  logoUri: string;
  previewUri: string;
  title: {
    [key: string]: string;
  };
  language: string;
  showInCrosstable: boolean;
  users: IUsers[];
  frontConfig: IComplexFrontConfig;
}

export enum TypeOfStatus {
  fixed = 'fixed time',
  static = 'static'
}

export interface IStatus extends IDogeFields {
  amoCanBeSetForDeal: boolean;
  amo_can_be_set_for_deal: boolean;
  amo_only_one_deal_placement: boolean;
  can_be_changed: boolean;
  can_be_set: boolean;
  color: string;
  crosstableColor: string | null;
  created_by: string;
  complex: string;
  crosstable_name: {
    [key: string]: string;
  };
  crosstableName: {
    [key: string]: string;
  };
  id: number;
  module_name: {
    [key: string]: string;
  };
  moduleName: {
    [key: string]: string;
  };
  name: {
    [key: string]: string;
  };
  show_in_module: string;
  temporary_type: TypeOfStatus|null;
  update_time_interval: number|null;
}
