import React from 'react';

export const SvgHeart: React.FC<{width?: string, height?: string, fill?: string}> = ({ width = 22, height = 22, fill = 'none' }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill={fill} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1143_732)">
        <path
          className="appStrokeStyles"
          d="M19.5003 12.572L12.0003 20L4.5003 12.572C4.00561 12.0906 3.61594 11.512 3.35585 10.8726C3.09575 10.2333
           2.97086 9.54695 2.98904 8.85693C3.00721 8.16692 3.16806 7.48814 3.46146 6.86334C3.75485 6.23854 4.17444
            5.68126 4.69379 5.22658C5.21314 4.77191 5.82101 4.42969 6.47911 4.22148C7.13722 4.01327 7.83131 3.94359
             8.51767 4.0168C9.20403 4.09002 9.8678 4.30456 10.4672 4.64691C11.0666 4.98926 11.5885 5.452 12.0003
              6.006C12.4138 5.45602 12.9364 4.99732 13.5354 4.65861C14.1344 4.31989 14.7968 4.10845 15.4812
               4.03752C16.1657 3.96659 16.8574 4.0377 17.5131 4.24639C18.1688 4.45509 18.7743 4.79687 19.2919
                5.25036C19.8094 5.70386 20.2277 6.25929 20.5207 6.88189C20.8137 7.5045 20.975 8.18088 20.9946
                 8.86871C21.0142 9.55653 20.8916 10.241 20.6344 10.8793C20.3773 11.5175 19.9912 12.0958 19.5003 12.578"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1143_732">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
