import React, { useCallback } from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';

import { useFavoriteStorage, useTranslations } from 'src/hooks';
import { locals } from 'src/constants/locals';
import { useHeaderRoute } from 'src/components/AppHeader/useHeaderRoute';
import { SvgHeart } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgHeart';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import { whiteColor } from 'src/constants';
import { useHistory } from 'react-router';
import { useComparePageLink } from 'src/hooks/useComparePageLink';
import { WrapperMobileItem } from '../_WrapperMobileItem';
import { useStyles } from '../../styles';

interface IFavoritesBottom {
  isActive: boolean;
}

export const FavoritesBottom: React.FC<IFavoritesBottom> = React.memo(({
  isActive,
}) => {
  const theme: Theme = useTheme();
  const history = useHistory();
  const styles = useStyles({});
  const { comparePageRouteLink } = useComparePageLink();
  const { getTranslations } = useTranslations();
  const { favIdsList } = useFavoriteStorage();

  const RenderFavoriteIconWithCount = useCallback(() => {
    return (
      <Box
        sx={{
          '& .appStrokeStyles': {
            stroke: !isActive ? theme.palette.primary.main : whiteColor,
          },
        }}
      >
        <SvgHeart fill={(favIdsList && favIdsList.length && !isActive) ? theme.palette.primary.main : whiteColor} />
        {favIdsList && favIdsList.length !== 0 && (
          <>
            <div className={styles.wrapperFavoriteCount}>
              <span>{`${favIdsList.length > 9 ? '9+' : favIdsList.length}`}</span>
            </div>
          </>
        )}
      </Box>
    );
  }, [styles, favIdsList]);

  return (
    <WrapperMobileItem
      isActive={isActive}
      iconItem={<RenderFavoriteIconWithCount />}
      text={getTranslations(locals.appTranslations.appFavorites)}
      handleClick={() => history.push(comparePageRouteLink)}
    />
  );
});
