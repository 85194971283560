import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  useTypeDisplayedSpinner,
  useTypeOfSpinner,
  useShowLessFiler,
  useTranslations,
  useBuildType,
  useHideFilterZone, useGetCurrentMarkup,
} from 'src/hooks';
import { requestSelectors } from 'src/store/requests/selectors';
import { TypeViewIconName, listOfTypes, IListOfTypes } from 'src/store/type-displayed-spinner/constant';
import { getMarkups } from 'src/store/requests/spinner-views/selector';
import { pathList } from 'src/route-list';
import { IMarkUps } from 'src/typings/spinner';
import { TypesOfSpinner } from 'src/typings/types-of-spinner';
import { Actions } from 'src/store/floors/actions';
import { getCurrentLang } from 'src/store/app/selectors';
import { useSetSection } from 'src/hooks/floors/use-set-section';
import {
  AroundImage,
  LayoutsImage,
  FloorsImage,
  CrosstableIcon,
} from './components';
import { MasterPlanDropdown } from './components/MasterPlanDropdown';
import { useStyles as useStylesSpinner } from './styles-spinner';
import { useStyles as useStylesCrossTable } from './styles-crosstable';

interface ITypeDisplayedSpinner {
  isActive?: boolean;
  pathChanged?: (path: string) => void;
}

export const TypeDisplayedSpinner: React.FC<ITypeDisplayedSpinner> = React.memo(({
  isActive,
  pathChanged,
}) => {
  const history = useHistory();
  const currentLang = useSelector(getCurrentLang);
  const {
    apartmentFloor, apartmentSection, apartmentHouse, apartmentNumeration,
  }: { apartmentFloor: string, apartmentSection: string, apartmentHouse: string, apartmentNumeration: string } = useParams();
  const { isSpinner, isCrossTable } = useBuildType();
  const markups: IMarkUps | any = useSelector(getMarkups);
  const mainColor = useSelector(requestSelectors.app.getMainColors);
  const { isMainSpinner, currentSpinner } = useTypeOfSpinner();
  const styles = isSpinner ? useStylesSpinner(mainColor) : useStylesCrossTable();
  const {
    selectedViews,
    getSelectedViewId,
    handleOnChangeTypeMap,
    handleDropToPreviousView,
  } = useTypeDisplayedSpinner();
  const { getTranslations } = useTranslations();
  const { handleToggleShowAllFilter } = useShowLessFiler();
  useHideFilterZone();
  const { setSection } = useSetSection();

  useEffect(() => {
    if (apartmentFloor && apartmentSection) {
      handleOnChangeTypeMap(TypesOfSpinner.viewFloors);
      setSection('section_id', apartmentSection);
    }
  }, [apartmentFloor, apartmentSection, apartmentHouse, apartmentNumeration]);

  const handleMasterPlan = useCallback((type: IListOfTypes) => {
    handleOnChangeTypeMap(type.id);
    handleDropToPreviousView();
    let path = type.path || '/';
    if (!isActive) {
      path = pathList.home;
    }
    if (!isCrossTable) {
      history.push(path);
    }
    pathChanged?.(path);
  }, [isActive, history, handleOnChangeTypeMap, handleDropToPreviousView]);

  const handleTypeBtn = useCallback((type: IListOfTypes) => {
    handleOnChangeTypeMap(type.id);
    if (type.showNestedFilter) {
      handleToggleShowAllFilter(true);
    }
    if (type.isMain) {
      handleDropToPreviousView();
    }
    let path = type.path || '/';
    if (!isActive) {
      path = pathList.home;
    }
    if (!isCrossTable) {
      history.push(path);
    }
    pathChanged?.(path);
  }, [history, isActive, handleOnChangeTypeMap, handleToggleShowAllFilter, handleDropToPreviousView]);

  const renderIcon = (iconName: TypeViewIconName) => {
    switch (iconName) {
      case TypeViewIconName.svg360:
        return <AroundImage />;
      case TypeViewIconName.layouts:
        return <LayoutsImage />;
      case TypeViewIconName.floors:
        return <FloorsImage />;
      case TypeViewIconName.model2d:
        return <CrosstableIcon />;
      default:
        return null;
    }
  };

  return (
    <>
      {
        selectedViews.map((view) => {
          const type: IListOfTypes = listOfTypes[view.type];
          if (type.isNested
              && isMainSpinner
              && currentSpinner?.name === 'complex'
              && Array.isArray(markups.complex)
          ) return null;

          if (view.type === TypesOfSpinner.viewSpinner) {
            return (
              <MasterPlanDropdown
                key={type.id}
                getSelectedViewId={getSelectedViewId}
                view={view}
                isActive={!!isActive}
                handleClick={() => handleMasterPlan(type)}
              />
            );
          }

          return (
            <div
              key={type.id}
              className={`${styles.wrapperTypeItem} ${
                type.id === getSelectedViewId && isActive ? styles.selectedStyle : ''}`}
              role="button"
              tabIndex={0}
              aria-hidden="true"
              onClick={() => handleTypeBtn(type)}
            >
              <div className={styles.iconTypeStyles}>
                { renderIcon(type.iconName) }
              </div>
              <span>{ view.name.translates?.[currentLang] || view.name.systemName }</span>
            </div>
          );
        })
      }
    </>
  );
});
