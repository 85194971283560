export const getValueByPlacementSystemName = (patch: any | null, systemName: string, lang: string): string | number => {
  let tempPatch = patch;
  if (systemName) {
    const splitedSystemName = systemName.split('.');
    let result: string = '';
    if (Array.isArray(splitedSystemName)) {
      splitedSystemName.forEach((pathStep, index) => {
        if (tempPatch && pathStep && Object.prototype.hasOwnProperty.call(tempPatch, pathStep)) {
          tempPatch = tempPatch[pathStep];
          if (Array.isArray(tempPatch)) {
            const resultArray: string[] = [];
            tempPatch.forEach((item) => {
              resultArray.push(getValueByPlacementSystemName(item, splitedSystemName[index + 1], lang).toString());
            });
            result = resultArray.join(',');
          }
        }
      });
      if (result) {
        return result;
      }
    }
  }
  if (tempPatch !== null && typeof tempPatch === 'object' && !Array.isArray(tempPatch)) {
    return tempPatch[lang];
  }
  return tempPatch || '';
};
