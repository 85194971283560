/* eslint-disable max-len */
/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable new-cap */
/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import { jsPDF } from 'jspdf';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { getComplexId, getCurrentLang } from 'src/store/app/selectors';
import {
  useBuildType, useGetComplex, useGetRequestAppData, useIsDevMode, useIsMobile,
} from 'src/hooks';

import Box from '@mui/material/Box';
import { ButtonApp } from 'src/components/ApartmentPageV2/Button';
import { SvgPDF } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgPDF';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useViewConfig } from 'src/hooks/useViewConfig';
import { getShowFlatshow } from 'src/store/requests/app/selector';
import { IComplex, IGtmEventsTypes } from 'src/typings/complexes';
import { isProdEnv } from 'src/helpers/buildEnv';
import { useStyles } from '../styles';
import { crosstableUrl, rootUrl } from '../../../../constants/config';
import { IFilterAllData, ListOfKeysFilterAllData } from '../../../../typings/filter';
import { getView2dData } from '../../../../store/view-2d/selectors';
import { insertHtmlForPdf } from '../../../../helpers/insertHtmlForPdf';

function numberWithCommas(x: string) {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
}

function calculateDate(descriptionColumnValue: string = '') {
  const currentDate = new Date();
  const dateRegex = /{currentDate\s*(?:\+\s*(\d+)_month)?\s*(?:\+\s*(\d+)_day)?}/;
  const matches = dateRegex.exec(descriptionColumnValue);

  if (matches) {
    const monthsToAdd = matches[1] ? parseInt(matches[1], 10) : 0;
    const daysToAdd = matches[2] ? parseInt(matches[2], 10) : 0;

    currentDate.setMonth(currentDate.getMonth() + monthsToAdd);
    currentDate.setDate(currentDate.getDate() + daysToAdd);

    return currentDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  }

  return descriptionColumnValue;
}

interface ICreatePdf {
  id: string,
  placement?: IFilterAllData,
  circularProgressSize?: number,
  title?: string,
  disabled?: boolean,
  isPaymentPlanBtn?: boolean,
  selectedPaymentPlans?: any | null,
  openPaymentPlansModal?: (state: boolean) => void,
  isLoading?: (loading: boolean) => void,
  onCustomPDF?: (error: Error) => void,
  complex?: IComplex | null,
}

export const CreatePdf: React.FC<ICreatePdf> = ({
  id,
  placement,
  circularProgressSize = 30,
  title,
  disabled = false,
  isLoading,
  onCustomPDF,
  isPaymentPlanBtn = false,
  openPaymentPlansModal,
  selectedPaymentPlans,
  complex,
}) => {
  const isShowFlatshow = useSelector(getShowFlatshow);
  const styles = useStyles({});
  const { isCrossTable } = useBuildType();
  const { appRequestData } = useGetRequestAppData();
  const complexIdState = useSelector(getComplexId);
  const view2dData = useSelector(getView2dData);
  const currentLang = useSelector(getCurrentLang);
  const [loading, setloading] = useState(false);
  const { isPaymentPlansSeparated } = useViewConfig();
  const theme: Theme = useTheme();
  const { isDevMode } = useIsDevMode();
  useEffect(() => {
    insertHtmlForPdf();
  }, []);
  const { isMobileScreen } = useIsMobile();

  useEffect(() => {
    if (selectedPaymentPlans) {
      handlerIcon();
    }
  }, [selectedPaymentPlans]);

  function convertToPNG(src: any, color: any) {
    return new Promise((resolve) => {
      const img = new Image();

      img.onload = () => {
        let { clientWidth, clientHeight } = src;
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        if (canvas) {
          clientWidth *= 4;
          clientHeight *= 4;

          canvas.width = clientWidth;
          canvas.height = clientHeight;
          // @ts-ignore
          context.fillStyle = color; // <- background color
          // draw background / rect on entire canvas
          // @ts-ignore
          context.fillRect(0, 0, canvas.width, canvas.height);
          // @ts-ignore
          context.drawImage(img, 0, 0, clientWidth, clientHeight);

          src.onload = resolve;

          src.src = canvas.toDataURL('image/jpeg', 1.0);
        }
      };

      img.crossOrigin = 'Anonymous';
      img.src = src.src;
    });
  }

  function getUrlExtension(url: string) {
    // @ts-ignore
    return url.split(/[#?]/)[0].split('.').pop().trim().toLowerCase();
  }

  function convertAllSVGToPNG(callback: any) {
    const promises: any[] = [];
    const tempPdf = document.querySelector('#tempPDF');
    if (tempPdf) {
      // @ts-ignore
      tempPdf.querySelectorAll('img').forEach((img) => promises.push(convertToPNG(img, img.closest('#pdf').style.backgroundColor)));
    }
    callback && Promise.all(promises).then(callback);
  }

  function generatePDF() {
    // @ts-ignore
    const doc = new jsPDF('p', 'pt', 'a4');
    const languageWithLongWords = currentLang === 'ge';
    // const fontName = languageWithLongWords ? 'Segoeui' : 'Arial';
    const fontName = 'Segoeui';
    doc.addFont(`${rootUrl}/web/js/pdfAssets/fonts/${fontName}.ttf`, 'PdfFont', 'normal');
    doc.addFont(`${rootUrl}/web/js/pdfAssets/fonts/${fontName}-Bold.ttf`, 'PdfFont-Bold', 'normal');
    doc.setFont('PdfFont'); // set font
    doc.setFont('PdfFont-Bold'); // set font

    const count = document.querySelectorAll('#pdf').length;

    const tempPdfEl = document.querySelector('#tempPDF');
    if (tempPdfEl) {
      if (languageWithLongWords) {
        tempPdfEl.classList.add('languageWithLongWords');
      }
      const house = placement ? placement[ListOfKeysFilterAllData.placementHouse] : '';
      const isOneSection = view2dData && placement && house && Object.keys(view2dData[house]).length === 1;
      const currentDate = new Date();
      const formattedDate = format(currentDate, 'MMM dd, yyyy');
      const fileTitle = placement ? `${isCrossTable ? appRequestData?.crosstable_title : appRequestData?.module_title}_${
        placement[ListOfKeysFilterAllData.placementHouse]}${
        !isOneSection ? `_${placement[ListOfKeysFilterAllData.placementSection]}` : ''}_${
        placement[ListOfKeysFilterAllData.placementFloor]}_${
        placement[ListOfKeysFilterAllData.placementPlanningType]}_${
        placement[ListOfKeysFilterAllData.placementNumeration]}|${
        placement[ListOfKeysFilterAllData.placementTotalArea]
      }|${formattedDate}` : 'Appartment_Passport';
      doc.html(tempPdfEl as HTMLElement, {
        callback() {
          doc.deletePage(count + 1);
          doc.save(`${fileTitle}.pdf`);
          const formSubmitGTMEvent = complex?.frontConfig.website.gtm.events.find((event) => event.type === IGtmEventsTypes.pdfDownload);
          console.log('GTM event', formSubmitGTMEvent, isDevMode, isProdEnv());
          if (!isCrossTable && !isDevMode && isProdEnv() && window.dataLayer && formSubmitGTMEvent && typeof formSubmitGTMEvent.eventJson === 'object' && !Array.isArray(formSubmitGTMEvent.eventJson)) {
            console.log('GTM event click', formSubmitGTMEvent.eventJson);
            window.dataLayer.push(formSubmitGTMEvent.eventJson);
          }
          setloading(false);
          isLoading?.(false);
          const tempPdf = document.getElementById('tempPDF');
          if (tempPdf) {
            tempPdf.innerHTML = '';
          }
        },
        margin: [0, 0, 0, 0],
        x: 0,
        y: 0,
      });
    }
  }

  function rotate(srcBase64: string, degrees: number, color: any, callback: ((arg0: string) => void) | undefined) {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const image = new Image();

      image.onload = function () {
        canvas.width = degrees % 180 === 0 ? image.width : image.height;
        canvas.height = degrees % 180 === 0 ? image.height : image.width;

        if (getUrlExtension(image.src) === 'svg') {
          canvas.width *= 4;
          canvas.height *= 4;
          image.width *= 4;
          image.height *= 4;
        }
        if (canvas.width > canvas.height) canvas.height = canvas.width;
        if (canvas.height > canvas.width) canvas.width = canvas.height;
        if (ctx) {
          ctx.fillStyle = color;
          // draw background / rect on entire canvas
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.translate(canvas.width / 2, canvas.height / 2);
          if (degrees !== 0) {
            ctx.rotate(degrees * (Math.PI / 180));
          }
          ctx.drawImage(image, canvas.width / -2 + 36, canvas.height / -2 + 36, image.width - 72, image.height - 72);
        }

        // @ts-ignore
        callback(canvas.toDataURL('image/jpeg', 1.0));
        // @ts-ignore
        resolve();
      };
      image.crossOrigin = 'Anonymous';
      image.src = srcBase64;
    });
  }

  const combine = (imgSource: string, overlay: any, color: any) => {
    return new Promise(((resolve) => {
      const img = new Image();
      img.onload = function () { resolve(img); };
      img.crossOrigin = 'Anonymous';
      img.src = imgSource;
    })).then((image: any) => {
      const clientWidth = image.naturalWidth;
      const clientHeight = image.naturalHeight;
      const canvas = document.createElement('canvas');

      canvas.width = clientWidth;
      canvas.height = clientHeight;

      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.fillStyle = color; // <- background color
        // draw background / rect on entire canvas
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(image, 0, 0, clientWidth, clientHeight);
        if (overlay.pointsArray) {
          overlay.pointsArray.forEach((point: string) => {
            const pointsArray = point.split(' ');
            ctx.moveTo(Number(pointsArray[0]), Number(pointsArray[1]));
            for (let item = 2; item < pointsArray.length - 1; item += 2) {
              ctx.lineTo(Number(pointsArray[item]), Number(pointsArray[item + 1]));
            }
          });
        } else {
          const pointsArray = overlay.points.split(' ');
          ctx.moveTo(pointsArray[0], pointsArray[1]);
          for (let item = 2; item < pointsArray.length - 1; item += 2) {
            ctx.lineTo(pointsArray[item], pointsArray[item + 1]);
          }
        }
        ctx.fillStyle = `${overlay.fill}B3`;
        ctx.fill();
        ctx.strokeStyle = overlay.stroke;
        ctx.stroke();
        ctx.closePath();
        // ctx.drawImage(svg, 0, 0, clientWidth, clientHeight);
      }

      // return the resulting image in base64 form
      return canvas.toDataURL('image/jpeg');
    });
  };

  const handlerIcon = () => {
    if (isPaymentPlansSeparated && isPaymentPlanBtn && !selectedPaymentPlans) {
      openPaymentPlansModal && openPaymentPlansModal(true);
      return;
    }
    const href = `${rootUrl}/client/module/get-placement-pdf-data?lang=${currentLang}&id=${id}&frontType=${isCrossTable ? 'crosstable' : 'module'}`;
    let compass: any;
    setloading(true);
    isLoading?.(true);
    fetch(href)
      .then((res) => res.json())
      .then(async (data) => {
        const pdfViewTypeRes = await fetch(
          `${rootUrl}/client/module/get-placement-pdf-config-view-type-field?id=${complexIdState}&frontType=${isCrossTable ? 'crosstable' : 'module'}`,
        );
        const pdfViewTypeData = await pdfViewTypeRes.json();
        const onePageData: any = {};
        const onePageTrigger = pdfViewTypeData.view_type === 'one-page';
        const { backgroundColor, clientLogoURL } = data[0];
        const currentDate = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
        if (!isPaymentPlansSeparated || !isPaymentPlanBtn || selectedPaymentPlans.type === 'common') {
          for (let i = 0; i < data.length; i++) { // выведет 0, затем 1, затем 2
            const item = data[i];
            item.notes = item.notes ? [...item.notes] : [];
            item.isShowFlatshow = isShowFlatshow;
            Object.keys(item.placementData).forEach((fieldName) => {
              item.placementData[fieldName] = item.placementData[fieldName] ? numberWithCommas(item.placementData[fieldName]) : item.placementData[fieldName];
            });
            item.pageNumber = i + 1;
            item.currentDate = currentDate;
            if (item.floorWindroseData) {
              const response = await fetch(`${rootUrl}/entity-options/get-spinner-config?entity=floor&id=${item.floorWindroseData.id}`);
              const res = await response.json();
              item.thirdImageURL = res._windRose.src;
              item.degree = res._windRose.degree;

              if (item.thirdImageURL) {
                await rotate(item.thirdImageURL, item.degree, item.backgroundColor, (resultBase64: any) => {
                  compass = resultBase64;
                });
              }
            }
            if (item.firstImageOverlaySVGData) {
              const result = await combine(item.firstImageURL, item.firstImageOverlaySVGData, item.backgroundColor);
              item.firstImageURL = result;
            }
            if (item.secondImageOverlaySVGData) {
              const result = await combine(item.secondImageURL, item.secondImageOverlaySVGData, item.backgroundColor);
              item.secondImageURL = result;
            }
            item.thirdImageURL = compass;
            if (onePageTrigger) {
              if (i == 0) {
                onePageData.salesDepartmentFieldName = item.salesDepartmentFieldName;
                onePageData.complexAddressFieldName = item.complexAddressFieldName;
                onePageData.backgroundColor = item.backgroundColor;
                onePageData.complexAddress = item.complexAddress;
                onePageData.apartmentLayout = item.firstImageURL;
                onePageData.placementData = item.placementData;
                onePageData.clientLogoURL = item.clientLogoURL;
                onePageData.complexName = item.complexName;
                onePageData.phones = item.phones || [];
                onePageData.notes = item.notes || [];
                onePageData.compass = compass;
                onePageData.isShowFlatshow = item.isShowFlatshow;
              } else if (i == 1 && data.length === 2 || i == 1 && data.length === 3 || i == 2 && data.length === 4) {
                onePageData.floorPlan = item.firstImageURL;
                onePageData.sectionDiagram = item.secondImageURL;
              } else if (i == 2 && data.length === 2 || i == 2 && data.length === 3 || i == 3 && data.length === 4) onePageData.renderFacade = item.firstImageURL;
            } else {
              // @ts-ignore
              document.getElementById('tempPDF').innerHTML += tmpl('pdf-template', item);
            }
          }
          if (onePageTrigger) {
            // @ts-ignore
            document.getElementById('tempPDF').innerHTML += tmpl('pdf-template-short', onePageData);
          }
        }
        if (!isPaymentPlansSeparated || (isPaymentPlansSeparated && isPaymentPlanBtn && selectedPaymentPlans.type !== 'common')) {
          let paymentResult;
          if (!selectedPaymentPlans) {
            const pdfPaymentRes = await fetch(
              `${crosstableUrl}/mapped_placement_pdf_payment_plans?language=${currentLang}&placementId=${id}&frontType=${isCrossTable ? 'crosstable' : 'module'}`,
            );
            paymentResult = await pdfPaymentRes.json();
          } else {
            paymentResult = [selectedPaymentPlans];
          }
          if (paymentResult?.length) {
            let currentPageNumber = !isPaymentPlansSeparated ? data.length : 0;
            for (let i = 0; i < paymentResult.length; i++) {
              const paymentData = paymentResult[i];
              currentPageNumber += 1;
              paymentData.notes = paymentData.notes ? [...paymentData.notes] : [];
              paymentData.pageNumber = currentPageNumber;
              paymentData.clientLogoURL = clientLogoURL;
              paymentData.backgroundColor = backgroundColor;
              paymentData.calculateDate = calculateDate;
              paymentData.currentDate = currentDate;
              paymentData.isShowFlatshow = isShowFlatshow;
              // @ts-ignore
              document.getElementById('tempPDF').innerHTML += tmpl('payment-template', paymentData);
              const tempPDFContainer = document.getElementById('tempPDF');
              if (tempPDFContainer) {
                const lastBlock = tempPDFContainer.lastElementChild;
                if (!lastBlock) {
                  return;
                }
                // @ts-ignore
                const { children } = lastBlock;

                const footer = lastBlock.querySelector('.paymentPageFooter');
                if (!footer) {
                  return;
                }
                // @ts-ignore
                const footerTopOffset = footer.offsetTop - 40;
                let needNewBlock = false;

                // Проверяем, есть ли элементы, которые не помещаются в текущий блок
                for (let c = 0; c < children.length; c++) {
                  // @ts-ignore
                  if (children[c].offsetTop > footerTopOffset && !children[c].classList.contains('paymentPageFooter')) {
                    needNewBlock = true;
                    break;
                  }
                }

                if (needNewBlock) {
                  currentPageNumber += 1;

                  const newBlock = lastBlock.cloneNode(true);

                  // @ts-ignore
                  const newfooter = newBlock.querySelector('.paymentPageFooter');
                  if (newfooter) {
                    const pageNumberElement = newfooter.querySelector('.pageNumber');
                    if (pageNumberElement) {
                      pageNumberElement.textContent = `${currentPageNumber}`;
                    }
                  }

                  // @ts-ignore
                  // const newHeader = newBlock.querySelector('.paymentPageHeader').cloneNode(true);
                  // @ts-ignore
                  while (newBlock.children.length > 3) {
                    // @ts-ignore
                    newBlock.removeChild(newBlock.children[2]);
                  }

                  for (let c = children.length - 1; c >= 0; c--) {
                    // @ts-ignore
                    if (children[c].offsetTop > footerTopOffset && !children[c].classList.contains('paymentPageFooter')) {
                      // @ts-ignore
                      newBlock.insertBefore(children[c], newBlock.children[2]);
                    }
                  }
                  // @ts-ignore
                  // newBlock.insertBefore(newHeader, newBlock.children[1]);

                  tempPDFContainer.appendChild(newBlock);
                }
              }
            }
          }
        }
        setTimeout(() => {
          convertAllSVGToPNG(generatePDF);
        }, 1000);
      })
      .catch((err: Error) => {
        onCustomPDF && onCustomPDF(err);
        setloading(false);
      });
  };

  return (
    <>
      {!isMobileScreen && (
        <ButtonApp
          className="createPdf"
          style={{
            cursor: loading ? 'auto' : 'pointer',
          }}
          disabled={disabled}
          onClick={!loading ? handlerIcon : undefined}
        >

          <Box
            display="flex"
            alignItems="center"
            gap="4px"
          >
            {loading ? (
              <CircularProgress size={circularProgressSize} sx={{ color: theme.palette.grey['400'] }} />
            ) : (
              <div className={styles.pdfIcon}>
                <SvgPDF />
              </div>
            )}
            <div className={styles.pdfIconTitle}>{title}</div>
          </Box>
        </ButtonApp>
      )}
      {isMobileScreen && (
      <>
        <div id="tempPDF-holder">
          <div id="tempPDF" />
        </div>
        <Box
          display="flex"
          alignItems="center"
          gap="4px"
        >
          {loading ? (
            <CircularProgress size="100%" sx={{ color: theme.palette.grey['400'] }} />
          ) : (
            <Box className="svg" onClick={!loading ? handlerIcon : undefined}>
              <SvgPDF />
            </Box>
          )}
        </Box>
      </>
      )}
    </>
  );
};
