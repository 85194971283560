/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable new-cap */
/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import { jsPDF } from 'jspdf';

import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { rootUrl } from 'src/constants';
import { IExportPdfData } from '../typings/table';
import { insertHtmlForTablePdf } from './insertHtmlForPdf';
import { getCurrentLang } from '../store/app/selectors';
import { useBuildType, useGetRequestAppData } from '../hooks';

interface TableData extends IExportPdfData {
  currentDate?: string;
  pageNumber?: number;
}

function convertAllSVGToPNG(callback: any) {
  const promises: any[] = [];
  const tempPdf = document.querySelector('#tempPDF');
  if (tempPdf) {
    // @ts-ignore
    tempPdf.querySelectorAll('img').forEach((img) => promises.push(convertToPNG(img, img.closest('#pdf').style.backgroundColor)));
  }
  callback && Promise.all(promises).then(callback);
}

function convertToPNG(src: any, color: any) {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => {
      let { clientWidth, clientHeight } = src;
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      if (canvas) {
        clientWidth *= 4;
        clientHeight *= 4;

        canvas.width = clientWidth;
        canvas.height = clientHeight;
        // @ts-ignore
        context.fillStyle = color; // <- background color
        // draw background / rect on entire canvas
        // @ts-ignore
        context.fillRect(0, 0, canvas.width, canvas.height);
        // @ts-ignore
        context.drawImage(img, 0, 0, clientWidth, clientHeight);

        src.onload = resolve;

        src.src = canvas.toDataURL('image/jpeg', 1.0);
      }
    };

    img.crossOrigin = 'Anonymous';
    img.src = src.src;
  });
}

function computeStyle(element: Element, property: string) {
  return parseFloat(window.getComputedStyle(element)[property as any]) || 0;
}

function calculateElementHeight(element: Element) {
  return (element as HTMLElement).offsetHeight
      + computeStyle(element, 'marginTop')
      + computeStyle(element, 'marginBottom')
      + computeStyle(element, 'paddingTop')
      + computeStyle(element, 'paddingBottom')
      + computeStyle(element, 'borderTopWidth')
      + computeStyle(element, 'borderBottomWidth');
}

function isRowOutOfPage(row: HTMLTableRowElement, availableHeight: number) {
  const rowBottom = row.offsetTop + calculateElementHeight(row);
  return rowBottom > availableHeight;
}

function updatePageNumber(newPage: HTMLElement, currentPageNumber: number) {
  const footer = newPage.querySelector('.tablePageFooter');
  if (footer) {
    const pageNumberElement = footer.querySelector('.pageNumber');
    if (pageNumberElement) {
      pageNumberElement.textContent = `Page ${currentPageNumber}`;
    }
  }
}

function renderPDFPages(projectData: TableData, tempPDFContainer: HTMLElement) {
  let currentPageNumber = 1;
  projectData.pageNumber = currentPageNumber;
  // @ts-ignore
  const template: string = tmpl('tablePage-template', projectData);

  tempPDFContainer.insertAdjacentHTML('beforeend', template);
  let currentPage = tempPDFContainer.querySelector('.tablePage');
  if (currentPage) {
    const headerElement = currentPage.querySelector('.tablePageHeader');
    const headerHeight = headerElement ? calculateElementHeight(headerElement) : 0;

    const footerElement = currentPage.querySelector('.tablePageFooter');
    const footerHeight = footerElement ? calculateElementHeight(footerElement) + 48 : 0;

    let tbody = currentPage.querySelector('tbody');
    if (!tbody) {
      return;
    }
    let rows = Array.from(tbody.querySelectorAll('tr'));
    let needNewPage = false;

    rows.forEach((row) => {
      if (isRowOutOfPage(row, (currentPage as HTMLElement).offsetHeight - headerHeight - footerHeight)) {
        needNewPage = true;
      }
    });

    while (needNewPage) {
      currentPageNumber++;
      const newPage: any = currentPage?.cloneNode(true);
      updatePageNumber((newPage as HTMLElement), currentPageNumber);

      const newTbody = (newPage as HTMLElement).querySelector('tbody');
      if (!newTbody) {
        return;
      }
      while (newTbody.firstChild) {
        newTbody.removeChild(newTbody.firstChild);
      }

      const rowsToMove = [];
      for (let i = rows.length - 1; i >= 0; i--) {
        if (isRowOutOfPage(rows[i], (currentPage as HTMLElement).offsetHeight - headerHeight - footerHeight)) {
          rowsToMove.unshift(rows[i]);
          tbody.removeChild(rows[i]);
        }
      }

      rowsToMove.forEach((row) => newTbody.appendChild(row));
      tempPDFContainer.appendChild(newPage);

      currentPage = newPage;
      tbody = newTbody;
      rows = Array.from(tbody.querySelectorAll('tr'));

      needNewPage = false;
      rows.forEach((row) => {
        if (isRowOutOfPage(row, (currentPage as HTMLElement).offsetHeight - headerHeight - footerHeight)) {
          needNewPage = true;
        }
      });
    }
  }
}

const useCreateTablePdf = () => {
  const currentLang = useSelector(getCurrentLang);
  const { appRequestData } = useGetRequestAppData();
  const { isCrossTable } = useBuildType();
  const [isLoading, setIsloading] = useState<boolean>(false);

  useEffect(() => {
    insertHtmlForTablePdf();
  }, []);

  const generatePDF = useCallback(() => {
    const doc = new jsPDF('p', 'pt', 'a4');
    const fontName = currentLang === 'ge' ? 'DejaVuSans' : 'Gilroy';
    doc.addFont(`${rootUrl}/web/js/pdfAssets/fonts/${fontName}.ttf`, 'PdfFont', 'normal');
    doc.addFont(`${rootUrl}/web/js/pdfAssets/fonts/${fontName}-Bold.ttf`, 'PdfFont-Bold', 'normal');
    doc.setFont('PdfFont'); // set font
    doc.setFont('PdfFont-Bold'); // set font

    doc.setFont('Inter-Regular'); // set font
    doc.setFont('Inter-Bold'); // set font

    const count = document.querySelectorAll('#pdf').length;
    const tempPdfEl = document.querySelector('#tempPDF');
    if (tempPdfEl) {
      const currentDate = new Date();
      const formattedDate = format(currentDate, 'MMM dd, yyyy');
      doc.html(tempPdfEl as HTMLElement, {
        callback() {
          doc.deletePage(count + 1);
          doc.save(`${isCrossTable ? appRequestData?.crosstable_title : appRequestData?.module_title}_${formattedDate}`);
          setIsloading(false);
          tempPdfEl.innerHTML = '';
        },
        margin: [0, 0, 0, 0],
        x: 0,
        y: 0,
      });
    }
  }, [currentLang, appRequestData, isCrossTable]);

  const createTablePdf = useCallback(
    (projectData: IExportPdfData) => {
      const tempPDFContainer = document.getElementById('tempPDF');
      const tableData: TableData = { ...projectData };
      tableData.currentDate = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
      if (tempPDFContainer) {
        setIsloading(true);
        tempPDFContainer.innerHTML = '';
        renderPDFPages(tableData, tempPDFContainer);

        setTimeout(() => {
          convertAllSVGToPNG(generatePDF);
        }, 1000);
      }
    },
    [generatePDF],
  );

  return { isLoading, createTablePdf };
};

export default useCreateTablePdf;
