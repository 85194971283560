import { useMemo } from 'react';
import { isString } from 'lodash';
import { useTranslations } from '../../../hooks';
import { IRangeAndSetData } from '../../../typings/filter';

export const useConvertToFormat = (data: (IRangeAndSetData | string)[]) => {
  const { getTranslations } = useTranslations();

  const currentData = useMemo(() => {
    return data?.map((item: IRangeAndSetData | string) => {
      const optionIsString = isString(item);
      return {
        key: optionIsString ? item : item?.key,
        text: optionIsString ? getTranslations(item) : item?.text,
        value: optionIsString ? item : item?.value,
      };
    }).sort((a, b) => a.text.localeCompare(b.text, undefined, { numeric: true, sensitivity: 'base' }));
  }, [data, getTranslations]);

  return {
    currentData: currentData || [],
  };
};
