import React from 'react';

export const SvgFullScreenOut: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 9H9V5" stroke="currentColor" strokeWidth="1.2" />
      <path d="M19 9H15V5" stroke="currentColor" strokeWidth="1.2" />
      <path d="M5 15H9V19" stroke="currentColor" strokeWidth="1.2" />
      <path d="M19 15H15V19" stroke="currentColor" strokeWidth="1.2" />
    </svg>
  );
};
